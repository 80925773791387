<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <input #input (keyup)="buscarVacante( input.value )" name="termino" type="text" class="form-control" placeholder="Buscar vacante por nombre de cliente...">
            </div>
        </div>
    </div>
</div>
<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="text-right">
                    <button [routerLink]="['/vacante/nuevo']" type="button" class="btn waves-effect waves-light btn-rounded btn-primary">
                                               <i class="fa fa-plus"> Agregar Vacante</i>
                    </button>
                </div>
                <h3 class="card-title"> Vacantes disponibles (<small>{{ vacante.length }}</small>)</h3>
                <div class="table-responsive">
                    <table *ngIf="!cargando" class="table table-striped animated fadeIn faster" width="100%" cellspacing="0">
                        <thead class="bg-theme">
                            <tr>
                                <th>
                                    <p class="font-weight-bold text-white">Vacante</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Cliente</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Puesto</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Solicitud</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Días sin Cubrir</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Status</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Tipo Vacante</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Acción</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let f of vacante | paginate: config; let i = index">
                                <td>{{ f.servicio }}</td>
                                <td>{{ f.nombreEmpresa | lowercase }}</td>
                                <td>{{ f.TipoDepartamento | lowercase}}</td>
                                <td>{{ f.fechaInicio | date: 'fullDate' }}</td>
                                <td>{{ f.diasTranscurridos }} </td>
                                <td>
                                    <label *ngIf="f.status=='1'" class="badge badge-success"> Disponible</label>
                                    <label *ngIf="f.status!='1'" class="badge badge-danger"> Baja</label>
                                </td>
                                <td>{{ f.tipoVacante }} </td>
                                <td>
                                    <button class="btn btn-danger" (click)="borrarVacante( f.claveVacante ,i)">
                                   <i class="fa fa-trash"></i>
                                  </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div align="right">
                        <pagination-controls (pageChange)="pageChanged($event)" autoHide="true" responsivo="true" directionLinks="true" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
                    </div>
                </div>
                <div *ngIf="cargando && result == false" class="alert alert-info text-center mt-3 animated fadeIn faster">
                    <h4 clas="alert-heading">Cargando</h4>
                    <p>
                        <i class="fa fa-sync-alt fa-spin fa 2x"></i>
                    </p>
                    <p class="mb-0">
                        Espere por favor
                    </p>
                </div>
                <div *ngIf="!cargando && result == true" class="alert alert-warning text-center mt-3">
                    <h4 clas="alert-heading">No Se encontraron registros</h4>
                    <p>
                        <i class="fa fa-exclamation fa 2x"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>