<!--*****************************************************************************************************-->
<!-- The Modal -->
<div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;  padding-left: 0px;">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header bg-theme">
                <h4 class="modal-title text-center text-white" id="myLargeModalLabel">DETALLE EMPLEADO</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body bg-light">
                <!-- ---------------------------------------------------------------------------------------------------------------- -->
                <div class="card bg-light">
                    <img [src]='empleadoID.urlphoto' class="align-self-center" width='162px' height='162px'>
                    <div class="card-body bg-light">
                        <h2 class="text-center">{{ empleadoID.nombre | titlecase}} {{empleadoID.apellido | titlecase}}</h2>
                        <ul class="list-group">
                            <div class="row">
                                <li class="list-group-item list-group-item-primary col-12">
                                    <p class="font-weight-bold text-center">DATOS PERSONALES</p>
                                </li>
                            </div>
                            <div class="row">
                                <li class="list-group-item bg-light col-6">
                                    <p class="font-weight-bold">LUGAR DE NACIMIENTO:</p> {{empleadoID.lugarDeNacimiento}}
                                </li>
                                <li class="list-group-item bg-light col-6">
                                    <p class="font-weight-bold">FECHA DE NACIMIENTO:</p> {{empleadoID.fechaDeNacimiento}}
                                </li>
                            </div>
                            <div class="row">
                                <li class="list-group-item bg-light col-4">
                                    <p class="font-weight-bold">MUNICIPIO:</p> {{empleadoID.delegacionMunicipio}}
                                </li>
                                <li class="list-group-item bg-light col-4">
                                    <p class="font-weight-bold">ESTADO:</p> {{empleadoID.estado}}
                                </li>
                                <li class="list-group-item bg-light col-4">
                                    <p class="font-weight-bold">COLONIA:</p> {{empleadoID.colonia}}
                                </li>
                            </div>
                            <div class="row">
                                <li class="list-group-item bg-light col-10">
                                    <p class="font-weight-bold text-center">DIRECCIÓN:</p> {{empleadoID.calle}}
                                </li>
                                <li class="list-group-item bg-light col-2">
                                    <p class="font-weight-bold text-center">CP:</p> {{empleadoID.cp}}
                                </li>
                            </div>
                            <div class="row">
                                <li class="list-group-item bg-light col-4">
                                    <p class="font-weight-bold">RFC:</p> {{empleadoID.rfc}}
                                </li>
                                <li class="list-group-item bg-light col-4">
                                    <p class="font-weight-bold">CURP:</p> {{empleadoID.curp}}
                                </li>
                                <li class="list-group-item bg-light col-4">
                                    <p class="font-weight-bold">CLAVE DE ELECTOR:</p> {{empleadoID.claveDeElector}}
                                </li>
                            </div>
                            <div class="row">
                                <li class="list-group-item bg-light col-3">
                                    <p class="font-weight-bold">LICENCIA DE CONDUCIR: </p> {{empleadoID.licenciaDeConducir}}
                                </li>
                                <li class="list-group-item bg-light col-3">
                                    <p class="font-weight-bold">CARTILLA MILITAR: </p> {{empleadoID.cartillaMilitar}}
                                </li>
                                <li class="list-group-item bg-light col-3">
                                    <p class="font-weight-bold">TELEFONO: </p> {{empleadoID.telefono}}
                                </li>
                                <li class="list-group-item bg-light col-3">
                                    <p class="font-weight-bold">EMAIL:</p> {{empleadoID.correoElectronico}}
                                </li>
                            </div>
                            <div class="row">
                                <li class="list-group-item list-group-item-primary col-12">
                                    <p class="font-weight-bold text-center">REGISTRO LABORAL</p>
                                </li>
                            </div>
                            <div class="row">
                                <li class="list-group-item bg-light col-6">
                                    <p class="font-weight-bold">NÚMERO DE EMPLEADO: </p> {{empleadoID.numeroDeEmpleado}}
                                </li>
                                <li class="list-group-item bg-light col-6">
                                    <p class="font-weight-bold">PUESTO:</p> {{empleadoID.puesto}}
                                </li>
                            </div>
                            <div class="row">
                                <li class="list-group-item bg-light col-4">
                                    <p class="font-weight-bold">SEGURO SOCIAL: </p> {{empleadoID.nns}}
                                </li>
                                <li class="list-group-item bg-light col-4">
                                    <p class="font-weight-bold">FECHA INGRESO:</p> {{empleadoID.fechaIngreso}}
                                </li>
                                <li class="list-group-item bg-light col-4">
                                    <p class="font-weight-bold">SERVICIO ACTUAL:</p> {{empleadoID.servicio}}
                                </li>
                            </div>
                            <div class="row">
                                <li class="list-group-item list-group-item-primary col-12">
                                    <p class="font-weight-bold text-center">CONTACTO EN CASO DE EMERGENCIAS</p>
                                </li>
                            </div>
                            <div class="row">
                                <li class="list-group-item bg-light col-5">
                                    <p class="font-weight-bold">NOMBRE: </p> {{empleadoID.nombreEmerge}}
                                </li>
                                <li class="list-group-item bg-light col-4">
                                    <p class="font-weight-bold">PARENTESCO:</p> {{empleadoID.parentescoEmerge}}
                                </li>
                                <li class="list-group-item bg-light col-3">
                                    <p class="font-weight-bold">TELEFONO:</p> {{empleadoID.telefonoEmerge}}
                                </li>
                            </div>
                            <div class="row">
                                <li class="list-group-item list-group-item-primary col-12">
                                    <p class="font-weight-bold text-center">CONTACTO BENEFICIARIO</p>
                                </li>
                            </div>
                            <div class="row">
                                <li class="list-group-item bg-light col-5">
                                    <p class="font-weight-bold">NOMBRE: </p> {{empleadoID.nombreBeneficiario}}
                                </li>
                                <li class="list-group-item bg-light col-4">
                                    <p class="font-weight-bold">PARENTESCO:</p> {{empleadoID.parentescoBeneficiario}}
                                </li>
                                <li class="list-group-item bg-light col-3">
                                    <p class="font-weight-bold">TELEFONO:</p> {{empleadoID.telefonoBeneficiario}}
                                </li>
                            </div>
                            <div class="row">
                                <li class="list-group-item list-group-item-primary col-12">
                                    <p class="font-weight-bold text-center">ULTIMO EMPLEO</p>
                                </li>
                            </div>
                            <div class="row">
                                <li class="list-group-item bg-light col-4">
                                    <p class="font-weight-bold">ULTIMO EMPLEO: </p> {{empleadoID.ultimoEmpleo}}
                                </li>
                                <li class="list-group-item bg-light col-2">
                                    <p class="font-weight-bold">PUESTO:</p> {{empleadoID.puestoUltimoEmpleo}}
                                </li>
                                <li class="list-group-item bg-light col-2">
                                    <p class="font-weight-bold">ANTIGUEDAD:</p> {{empleadoID.antiguedadUltimoEmpleo}}
                                </li>
                                <li class="list-group-item bg-light col-4">
                                    <p class="font-weight-bold">MOTVIO DE SEPARACION:</p> {{empleadoID.motivoSeparacionUltimoEmpleo}}
                                </li>
                            </div>

                        </ul>
                    </div>
                </div>
                <!------------------------------------------------------------------------------------------------------------------------>
            </div>
            <div class="modal-footer bg-light">
                <button type="button" class="btn btn-danger waves-effect text-left" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!--**********************************************************************************************************-->

<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <input name="filtroNombre" type="text" class="form-control" placeholder="Buscar Empleado..." [(ngModel)]="filtroNombre">
        </div>
    </div>
</div>
<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title font-weight-bold"> Empleados registrados ({{ empleado.length }})</h3>
                <div class="table-responsive">
                    <table *ngIf="!cargando && empleado.length > 0" class="table table-striped animated fadeIn faster" width="100%" cellspacing="0">
                        <thead class="bg-theme">
                            <tr>
                                <th>
                                    <p class="font-weight-bold text-white">Imagen</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Nombre</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Puesto</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">#</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Acciones</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let f of empleado | filterNameEmpleado: filtroNombre | paginate: config; let i = index">
                                <td><img [src]='f.urlphoto' width='100px' height='100px' /></td>
                                <td>{{ f.nombre | titlecase }} {{ f.apellido | titlecase }}</td>
                                <td>{{ f.puesto | titlecase }} </td>
                                <td>{{ f.numeroDeEmpleado | uppercase }} </td>

                                <td>
                                    <button (click)="detalleInfo(f.claveEmpleado)" class="btn btn-info btn-sm m-1" data-toggle="modal" data-target=".bs-example-modal-lg">        
                                    Consultar empleado
                                    </button>
                                    <button (click)="detalleInfo(f.claveEmpleado)" class="btn btn-primary btn-sm m-1" [routerLink]="['/uniforme',f.claveEmpleado]">        
                                        Asignar uniforme
                                        </button>
                                    <button (click)="detalleInfo(f.claveEmpleado)" class="btn btn-warning btn-sm m-1" [routerLink]="['/asignacionUniforme',f.claveEmpleado]">        
                                          Consultar Uniforme
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div align="right">
                        <pagination-controls (pageChange)="pageChanged($event)" autoHide="true" responsivo="true" directionLinks="true" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
                    </div>
                </div>
                <div *ngIf="cargando" class="alert alert-info text-center mt-3 animated fadeIn faster">
                    <h4 clas="alert-heading">Cargando</h4>
                    <p>
                        <i class="fa fa-sync-alt fa-spin fa 2x"></i>
                    </p>
                    <p class="mb-0">
                        Espere por favor
                    </p>
                </div>
                <div *ngIf="!cargando && result == true" class="alert alert-warning text-center mt-3">
                    <h4 clas="alert-heading">No Se encontraron registros</h4>
                    <p>
                        <i class="fa fa-exclamation fa 2x"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>