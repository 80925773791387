<!--*****************************************************************************************************-->
<!-- The Modal -->
<div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header bg-dark text-center">
                <h4 class="modal-title font-weight-bold text-white text-center" id="myLargeModalLabel">Folio papeleta: {{ papeleta }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <!-- ---------------------------------------------------------------------------------------------------------------- -->
                <div class="table-responsive">
                    <table class="table table-bordered animated fadeIn faster" width="100%" cellspacing="0">
                        <thead class="bg-dark">
                            <tr>
                                <th>
                                    <p class="font-weight-bold text-white">Tipo Uniforme</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Talla</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Tipo Entrega</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Cantidad</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Estado</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Fecha entrega</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Color</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Persona</p>
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let papeleta of uniformeID; let i = index">
                                <td>{{ papeleta.tipoUniforme}}</td>
                                <td>{{ papeleta.talla}}</td>
                                <td>{{ papeleta.descripcion}}</td>
                                <td>{{ papeleta.cantidad }}</td>
                                <td>{{ papeleta.estado }}</td>
                                <td>{{ papeleta.fechaEntrega | date }}</td>
                                <td>{{ papeleta.color }}</td>
                                <td>{{ papeleta.nombre | titlecase}} {{ papeleta.apellido | titlecase }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!------------------------------------------------------------------------------------------------------------------------>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-dark waves-effect text-left" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!--**********************************************************************************************************-->
<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <input name="filtroNombre" type="text" class="form-control" placeholder="Buscar folio..." [(ngModel)]="filtroNombre">
        </div>
    </div>
</div>
<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="text-right">
                    <button [routerLink]="['/contrataciones']" type="button" class="btn waves-effect waves-light btn-rounded btn-danger">
                                               <i class="fa fa-arrow-left"> Regresar</i>
                    </button>
                </div>
                <h3><strong>Papeletas clasificadas uniforme: </strong>{{ uniforme.length }}</h3>
                <div class="table-responsive">
                    <table *ngIf="!cargando" class="table table-striped animated fadeIn faster" width="100%" cellspacing="0">
                        <thead class="bg-theme">
                            <tr>
                                <th>
                                    <p class="font-weight-bold text-white">Folio papeleta</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Fecha de entrega</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Fecha registro en sistema</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Acción</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let f of uniforme | folioPapeleta: filtroNombre | paginate: config; let i = index">
                                <td>{{ f.folioPapeleta }}</td>
                                <td>{{ f.fechaEntrega | date }} </td>
                                <td>{{ f.fuc | date }} </td>
                                <td>
                                    <button class="btn btn-warning" (click)="detalleFolio(f.folioPapeleta)" data-toggle="modal" data-target=".bs-example-modal-lg">   
                                   <i class="fa fa-eye"></i>
                                </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div align="right">
                        <pagination-controls (pageChange)="pageChanged($event)" autoHide="true" responsivo="true" directionLinks="true" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
                    </div>
                </div>
                <div *ngIf="cargando && result == false" class="alert alert-info text-center mt-3 animated fadeIn faster">
                    <h4 clas="alert-heading">Cargando</h4>
                    <p>
                        <i class="fa fa-sync-alt fa-spin fa 2x"></i>
                    </p>
                    <p class="mb-0">
                        Espere por favor
                    </p>
                </div>
                <div *ngIf="!cargando && result == true" class="alert alert-warning text-center mt-3">
                    <h4 clas="alert-heading">No Se encontraron registros</h4>
                    <p>
                        <i class="fa fa-exclamation fa 2x"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>