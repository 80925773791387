import { Component, OnInit } from '@angular/core';
import { AlmacenService } from '../../almacen/services/almacen/almacen.service';
import { ReporteSemanal } from '../../models/reporteUniforme.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-reportesemanal',
  templateUrl: './reportesemanal.component.html',
  styleUrls: ['./reportesemanal.component.css']
})
export class ReportesemanalComponent implements OnInit {

  reporteUniforme: ReporteSemanal[] = [];
  reporteUniformeID: ReporteSemanal[] = [];
  token: string;

  constructor(private almacenService: AlmacenService) { }

  ngOnInit(): void {
  this.token = localStorage.getItem('token');
  this.cargarReporte();

  }
   

  cargarReporte(): void {

    this.almacenService.getReporteSemanal(this.token)
    .subscribe( (resp: any) => {
      console.log(resp)

      if (resp.response['0'].claveTipoUniforme != null) {
         this.reporteUniforme = resp.response;
    console.log('consulta: ',this.reporteUniforme);

      }
    });
  }

  cargarReporteID(id: string): void {

    this.almacenService.getReporteSemanalID(this.token, id)
    .subscribe( (resp: any) => {
      console.log(resp)

      if (resp.response['0'].claveTipoUniforme != null) {
         this.reporteUniformeID = resp.response;
    console.log('consulta: ',this.reporteUniformeID);

      }
    });
    
  }


  consultar(form: NgForm): void{

  }
  
}
