import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  menu: any = [
    {
      titulo: 'Principal',
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: 'Dhasboard', url: '/dhasboard' },
      ],
    }
  ];
  menuSistemas: any = [
    {
      titulo: 'Principal',
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: 'Dhasboard', url: '/dhasboard' },
       // { titulo: 'Estadistico', url: '/graficas1' },
      ],
    },
    {
      titulo: 'Cliente',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'Clientes', url: '/clientes' },
      ],
    },
    {
      titulo: 'Almacen',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'control', url: '/almacen' },
        { titulo: 'Equipos', url: '/equipos' },
        { titulo: 'Asignacion equipo', url: '/asignaciones' },
        { titulo: 'asignación servicios', url: '/asignaciones-servicios' }
      ],
    },
    {
      titulo: 'Uniformes',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'Registro', url: '/asignaciones-uniformes' },
        { titulo: 'Asignaciones', url: '/asignaciones-uniformes' },
        { titulo: 'Reportes', url: '/graficas1' },
      ],
    },
    {
      titulo: 'RH',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'contrataciones', url: '/contrataciones' },
        { titulo: 'bajas guardias', url: '/bajas' },
        { titulo: 'servicios', url: '/servicio-cliente' },
        { titulo: 'Vacantes', url: '/vacantes' },
      ],
    },
    {
      titulo: 'Administrativo',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'Empleados', url: '/contrataciones' },
        { titulo: 'Vacaciones', url: '#' },
        { titulo: 'Faltas', url: '#' },
        { titulo: 'Puestos', url: '#' },
      ],
    },
    {
      titulo: 'Reportes',
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: 'Reporte General', url: '/graficas1' },
       // { titulo: 'Estadistico', url: '/graficas1' },
      ],
    }
  ];

  menuDireccion: any = [
    {
      titulo: 'Principal',
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: 'Dhasboard', url: '/dhasboard' },
       // { titulo: 'Estadistico', url: '/graficas1' },
      ],
    },
    {
      titulo: 'Cliente',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'Clientes', url: '/clientes' },
      ],
    },
    {
      titulo: 'RH',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'contrataciones', url: '/contrataciones' },
        { titulo: 'servicios', url: '/servicio-cliente' },
        { titulo: 'Vacantes', url: '/vacantes' },
      ],
    },
    {
      titulo: 'Administrativo',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'Empleados', url: '/contrataciones' },
        { titulo: 'Vacaciones', url: '#' },
        { titulo: 'Faltas', url: '#' },
        { titulo: 'Puestos', url: '#' },
      ],
    }
  ];

  menuRH: any = [
    {
      titulo: 'Principal',
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: 'Dhasboard', url: '/dhasboard' },
       // { titulo: 'Estadistico', url: '/graficas1' },
      ],
    },
    {
      titulo: 'Cliente',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'Clientes', url: '/clientes' },
      ],
    },
    {
      titulo: 'RH',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'contrataciones', url: '/contrataciones' },
        { titulo: 'servicios', url: '/servicio-cliente' },
       // { titulo: 'servicios', url: '/graficas1' },
        { titulo: 'Vacantes', url: '/vacantes' },
      ],
    }
  ];

  menuContratacion: any = [
    {
      titulo: 'Principal',
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: 'Dhasboard', url: '/dhasboard' },
       // { titulo: 'Estadistico', url: '/graficas1' },
      ],
    },
    {
      titulo: 'RH',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'contrataciones', url: '/contrataciones' },
        { titulo: 'servicios', url: '/servicio-cliente' },
        { titulo: 'Vacantes', url: '/vacantes' },
      ],
    }
  ];

  menuOperaciones: any = [
    {
      titulo: 'Principal',
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: 'Dhasboard', url: '/dhasboard' },
      ],
    },
    {
      titulo: 'Plantilla',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'servicios', url: '/servicio-cliente' }
      ],
    }
  ];

  menuNominas: any = [
    {
      titulo: 'Principal',
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: 'Dhasboard', url: '/dhasboard' },
       // { titulo: 'Estadistico', url: '/graficas1' },
      ],
    },
    {
      titulo: 'Plantilla',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'servicios', url: '/servicio-cliente' },
        { titulo: 'contrataciones', url: '/contrataciones' },

      ],
    }
  ];

  menuAlmacen: any = [
    {
      titulo: 'Panel de control',
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: 'Dhasboard', url: '/dhasboard' },
       // { titulo: 'Estadistico', url: '/graficas1' },
      ],
    },
    {
      titulo: 'Almacen',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'Almacen', url: '/almacen' },
        { titulo: 'Asignación de equipo', url: '/asignaciones' },
        { titulo: 'asignación de uniforme', url: '/uniforme-asignado'}
      ],
    },
    {
      titulo: 'Plantilla',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'asignación servicios', url: '/asignaciones-servicios' },
        { titulo: 'contrataciones', url: '/contrataciones' },
        { titulo: 'administrativos', url: '/administrativos' },
        { titulo: 'bajas de guardias', url: '/bajas' },
      ],
    },
    {
      titulo: 'Reportes',
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: 'Reporte General', url: '/graficas1' },
        { titulo: 'Reporte Semanal', url: '/reportesemanal' },
      ],
    }
  ];

  menuComercial: any = [
    {
      titulo: 'Principal',
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: 'Dhasboard', url: '/dhasboard' },
       // { titulo: 'Estadistico', url: '/graficas1' },
      ],
    },
    {
      titulo: 'Comercial',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'clientes', url: '/clientes' },
      ],
    },
    {
      titulo: 'Servicios',
      icono: 'mdi mdi-wallet',
      submenu: [
        { titulo: 'servicios', url: '/servicio-cliente' }
      ],
    }
  ];
  constructor() { }
}
