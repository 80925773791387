export class ClienteModel {
    claveCliente: string;
    identificador: string;
    claveEmpresa: string;
    correoEletronico: string;
    nombreEmpresa: string;
    nombreComercial: string;
    rfc: string;
    telefono: string;
    urlphoto: string;
    fuc: string;
    fechaIngreso: string;
    statuscliente: string;
    altaRazonSocial: string;
    autorizado: string;
    constructor() {

    this.urlphoto = 'https://sistemnom.edrum.com.mx/images/company/cliente/icono.png';
    }
}
