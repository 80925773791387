<div class="animated fadeIn faster">
    <hr>
    <div class="row text-right">
        <div class="col">
            <button [routerLink]="['/almacen']" class="btn btn-danger">
                <i class="fa fa-arrow-circle-left"></i>
                Regresar
            </button>
        </div>
    </div>
    <div class="row animated fadeIn faster">
        <div class="col">
            <form (ngSubmit)="guardar( f )" #f="ngForm">
                <div class="form-group">
                    <label>Equipos Disponibles</label>
                    <select class="form-control" name="equipoID" [(ngModel)]="equipoID"
                        required>
                        <option value="{{p.claveProducto}}" *ngFor="let p of producto  let i=index">{{ i+1 }}-{{ p.identificador }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Empleado</label>
                    <select class="form-control" name="empleadoID" [(ngModel)]="empleadoID"
                        required>
                        <option value="{{e.claveEmpleado}}" *ngFor="let e of empleado">{{ e.nombre }} {{ e.apellido }} - {{ e.TipoDepartamento }}</option>
                    </select>
                </div>
                <hr>
                <div class="form-group text-center">
                    <button type="submit" class="btn btn-primary w-25"><i class="fa fa-save"></i> Guardar</button>
                </div>
            </form>
        </div>
    </div>
</div>