import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AlmacenService } from 'src/app/almacen/services/almacen/almacen.service';
import { UniformeModel } from '../../models/uniforme.model';
import { CategoriaUniformeModel } from '../../models/categoria-unifome';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-uniformes',
  templateUrl: './uniformes.component.html',
  styleUrls: ['./uniformes.component.css']
})

export class UniformesComponent implements OnInit {

  uniforme: UniformeModel[] = [];
  tipoUniformes: CategoriaUniformeModel = new CategoriaUniformeModel();
  tipoUniforme: string;
  cargando = false;
  result = false;
  token: string;
  config: any;
  checkStock: string;
  id: any;
  precio: string;

  texto = ['Stock Actual', 'Entradas', 'Salidas'];
  icon = ['fas fa-desktop', 'fas fa-desktop', 'fas fa-desktop'];
  numeros: any[] = [0, 0, 0];

  constructor(public almacenService: AlmacenService, private route: ActivatedRoute) { }

   pageChanged( event ) {
    this.config.currentPage = event;
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.checkStock = '';
    this.config = {
      itemsPerPage: 10,
      currentPage: 1
    };
    this.cargando = true;
    this.token = localStorage.getItem('token');
    this.cargarCategoria(this.id);
    this.cargarProductoEstadistico(this.id);
    this.cargarStock(this.id);
  }

  cargarCategoria(id: string){

    this.almacenService.getCategoriaUniforme(this.token, id)
    .subscribe( (resp: any) => {

      if (resp.response['0'].tipoUniforme != null) {
         this.tipoUniformes = resp.response['0'];
         console.log(this.tipoUniformes);
      } else {
        this.result = true;
      }
      this.cargando = false;
    });

  }

  cargarStock(id: string) {
    this.almacenService.getStock(this.token, id)
    .subscribe( (resp: any) => {

      if (resp.response['0'].claveOperAsignacionUniforme != null) {
         this.uniforme = resp.response;
         this.tipoUniforme = resp.response['0'].tipoUniforme;
         this.result = false;
      } else {
        this.result = true;
      }
      this.cargando = false;
    });
  }

  cargarProductoEstadistico(tipo: string) {
    this.almacenService.getStockEstadistico(this.token, tipo)
    .subscribe( (resp: any) => {

      if (resp.response['0'] != null) {
         this.numeros = [resp.response['0']['actual'], resp.response['0']['entrada'], resp.response['0']['salida']]
      } else {
        this.result = true;
      }
      this.cargando = false;
    });
  }

  buscarProducto(termino: string, tipo: string ) {
    this.cargando = true;
    if (termino.length <= 0 || termino === null ) {
      this.cargando = false;
      this.cargarStock(this.id);
      return;
    }

    this.almacenService.getUniformeSearch(this.token, termino, tipo )
    .subscribe( (resultado: any) => {
      if ( resultado.response.length < 1) {
        this.cargando = false;
        this.result = true;
        this.uniforme = [];
        return;
      } else {
        if ( resultado.response['0'] != null) {
        this.uniforme = resultado.response;
        this.cargando = false;
        this.result = false;
        }
       }
      return  (this.uniforme);
    });
  }

  actualizar(form: NgForm): void {

    if (form.invalid) {
      return ;
    }
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    });
    Swal.showLoading();
    let peticion: Observable<any>;
    if (this.tipoUniformes.claveTipoUniforme) {
      peticion = this.almacenService.actualizarCategoriaUniforme(this.token, this.tipoUniformes);
    } else {
      peticion.subscribe( resp => {
        Swal.fire({
          title: this.tipoUniformes.tipoUniforme,
          text: 'ocurrio un error, intentelo mas tarde correctamente',
          type: 'warning'
        });
      });
    }
    peticion.subscribe( resp => {
      Swal.fire({
        title: this.tipoUniformes.tipoUniforme,
        text: 'Se actualizo correctamente',
        type: 'success'
      });
    });


  }
}
