import { Component, OnInit } from '@angular/core';
import { ClienteServicioModel } from 'src/app/models/clienteServicio.model';
import { ServicioModel } from 'src/app/models/servicio.model';
import { ClientesService } from 'src/app/services/clientes/clientes.service';

@Component({
  selector: 'app-asignacion-servicios',
  templateUrl: './asignacion-servicios.component.html',
  styleUrls: ['./asignacion-servicios.component.css']
})
export class AsignacionServiciosComponent implements OnInit {

  clienteServicio: ClienteServicioModel[] = [];
  servicio: ServicioModel[] = [];
  cargando = false;
  result = false;
  token: string;
  nombreEmpresa: string;
  config: any;
  accionUpdate = false;
  constructor(public clienteService: ClientesService) { }

   pageChanged( event ) {
    this.config.currentPage = event;
  }

  ngOnInit() {
    this.config = {
      itemsPerPage: 10,
      currentPage: 1
    };
    this.cargando = true;
    this.token = localStorage.getItem('token');
    this.cargarClientes();
  }

  cargarClientes() {
    this.clienteService.getClienteServicios(this.token, localStorage.getItem('cveEmp'), 'cliente')
    .subscribe( (resp: any) => {

      if (resp.response['0'].claveCliente != null) {
         this.clienteServicio = resp.response;
      } else {
        this.result = true;
      }
      this.cargando = false;
    });
  }

  detalleInfo(id: string, modal: string) {
    this.servicio = null;
    if (modal === 'cubierto') {
    this.accionUpdate = false;
    this.clienteService.getDetalleServicio(this.token, id, localStorage.getItem('cveEmp'))
    .subscribe( (resp: any) => {
      if (resp.response['0'].nombreEmpresa != null) {
        this.servicio = resp.response;
        this.nombreEmpresa = resp.response['0'].nombreEmpresa;
      }
    });
   } else {
    this.accionUpdate = true;
   }
  }

  buscarServicioCliente(termino: string ) {
    this.cargando = true;
    if (termino.length <= 0 || termino === null ) {
      this.cargando = false;
      this.cargarClientes();
      return;
    }
    this.clienteService.getClienteServicio(this.token, termino, localStorage.getItem('cveEmp'), 'cliente' )
    .subscribe( (resultado: any) => {

      if ( resultado.response['0'] === null) {
        this.cargando = false;
        this.result = true;
        this.clienteServicio = [];
        return;
      } else {
        if ( resultado.response['0'] != null) {
        this.clienteServicio = resultado.response;
        this.cargando = false;
        this.result = false;
        } else {
          this.cargando = false;
          this.result = true;
          this.clienteServicio = [];
          return;

        }
       }
      return  (this.clienteServicio);
    });
  }

}
