import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Usuario } from 'src/app/models/usuario.model';
import { URL_SERVICIOS } from '../../config/config';
import { map, catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  userToken = '';
  usuario: Usuario = new Usuario();
  public token: any = '';

  constructor(public router: Router, public http: HttpClient) {
     this.leerToken();
     this.leerUsuario();
   }

  renuevaToken() {

    let url = URL_SERVICIOS + '/login/renuevatoken';
    url += '?token=' + this.token;

    return this.http.get( url ).pipe(
                 map( (resp: any) => {

                  this.token = resp.token;
                  localStorage.setItem('token', this.token );
                  return true;
                }),
                  catchError( (err, caught) => (
                  this.router.navigate(['/login']))
                )
                );
  }

   logout() {
    localStorage.removeItem('_id');
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    localStorage.removeItem('urlphoto');
    localStorage.removeItem('departamento');
    localStorage.removeItem('cveEmp');
  }

  login( usuario: Usuario, recordar: boolean) {
     if ( recordar === false ) {
      localStorage.removeItem('email');
     } else {
      if (recordar === true ) {
        localStorage.setItem('email', usuario.email);
        }
     }
     const authData = {
      usuario: usuario.email,
      pass: usuario.password
    };
     return this.http.post(`${URL_SERVICIOS}/login`, authData)
    .pipe(
      map( (resp: any) => {
        this.guardarToken( resp.response.token, resp.response.usuario.usuario, resp.response.usuario.urlphoto,
         resp.response.usuario.cveEmp, resp.response.usuario._id, resp.response.usuario.departamento, resp.response.usuario.cveDep);
        return resp;
      })
    );
  }

  private guardarToken(idToken: string, usuario: string, urlphoto: string, cveEmp: string, id: string, departamento: string,
                       cveDep: string) {
    this.userToken = idToken;
    localStorage.setItem('cveEmp', cveEmp);
    localStorage.setItem('_id', id);
    localStorage.setItem('token', idToken);
    localStorage.setItem('usuario', usuario);
    localStorage.setItem('urlphoto', urlphoto);
    localStorage.setItem('departamento', departamento);
    localStorage.setItem('cveDep', cveDep);
  }


  leerToken() {
    if (localStorage.getItem('token')) {
      this.userToken = localStorage.getItem('token');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  leerUsuario() {
    if (localStorage.getItem('usuario') && localStorage.getItem('urlphoto')) {
      this.usuario.nombre = localStorage.getItem('usuario');
      this.usuario.urlphoto = localStorage.getItem('urlphoto');
      this.usuario.role = localStorage.getItem('departamento');
    } else {
       this.usuario = null;
      }
    return this.usuario;
  }

  estaAutenticado(): boolean {
    return (this.userToken.length > 5) ? true : false;
  }
}
