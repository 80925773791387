import { Component, OnInit } from '@angular/core';
import { ProductoModel } from '../../models/producto.model';
import { ProductoService } from '../../services/producto/producto.service';

@Component({
  selector: 'app-asignaciones',
  templateUrl: './asignaciones.component.html',
  styleUrls: ['./asignaciones.component.css']
})
export class AsignacionesComponent implements OnInit {

  producto: ProductoModel[] = [];
  productoID = new ProductoModel(0, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '');
  cargando = false;
  result = false;
  token: string;
  config: any;
  checkFactura: string;
  texto = ['equipo registrado', 'equipo disponible', 'equipo asignado'];
  icon = ['fas fa-desktop', 'fas fa-desktop', 'fas fa-desktop'];
  numeros: any[];

  constructor(public productoService: ProductoService) { }

  pageChanged(event) {
    this.config.currentPage = event;
  }

  ngOnInit() {
    this.numeros = [0, 0, 0];
    this.checkFactura = '';
    this.config = {
      itemsPerPage: 10,
      currentPage: 1
    };

    this.cargando = true;
    this.token = localStorage.getItem('token');
    this.cargarProductoEstadistico();
    this.cargarProductoAsignado();
  }

  cargarProductoAsignado() {
    this.productoService.getProductoAsignado(this.token, localStorage.getItem('cveEmp'))
      .subscribe((resp: any) => {

        if (resp['0'].claveProducto != null) {
          this.producto = resp;
        } else {
          this.result = true;
        }
        this.cargando = false;
      });
  }

  cargarProductoEstadistico() {
    this.productoService.getProductoEstadistico(this.token, localStorage.getItem('cveEmp'))
      .subscribe((resp: any) => {
        if (resp['0'] != null) {
          const { Total, Asignado, Disponible } = resp['0'];
          this.numeros = [Total, Disponible, Asignado];
        } else {
          this.result = true;
        }
        this.cargando = false;
      });
  }

  buscarProducto(termino: string) {
    this.cargando = true;
    if (termino.length <= 0 || termino === null) {
      this.cargando = false;
      this.cargarProductoAsignado();
      return;
    }
    this.productoService.getProductoSearch(this.token, termino)
      .subscribe((resp: any) => {
        if (resp.length < 1) {
          this.cargando = false;
          this.result = true;
          this.producto = [];
          return;
        } else {
          if (resp['0'] != null) {
            this.producto = resp;
            this.cargando = false;
            this.result = false;
          }
        }
        return this.producto;
      });
  }

  buscarEquipo(termino: string) {
    this.cargando = true;
    if (termino.length <= 0 || termino === null) {
      this.cargando = false;
      this.cargarProductoAsignado();
      return;
    }
    this.productoService.getProductoSearchIdentificador(this.token, termino)
      .subscribe((resp: any) => {
        if (resp['0'] === null) {
          this.cargando = false;
          this.result = true;
          this.producto = [];
          return;
        } else {
          if (resp['0'] != null) {
            this.producto = resp;
            this.cargando = false;
            this.result = false;
          } else {
            this.cargando = false;
            this.result = true;
            this.producto = [];
            return;
          }
        }
        return (this.producto);
      });
  }

  detalleInfo(id: string) {
    if (id !== 'nuevo') {
      this.productoService.getProductoID(this.token, id)
        .subscribe(resp => {
          this.productoID = resp['0'];
        });
    }
  }

}
