<app-head-estadistico
[texto] = texto
[icono] = icon
[numeros] = numeros
></app-head-estadistico>

<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card ">

            <div class="card-body">

                <div class="row">
                    <h3 class="card-title col-sm-3" align="center">
                        Filtrar por: {{ checkStock }}
                    </h3>
                    <div class="form-check col-sm-2">
                        <input #todos class="form-check-input" type="radio" [(ngModel)]="checkStock"
                            (click)="buscarProducto(todos.value,id)" id="p_todas" value="" checked>
                        <label class="form-check-label" for="p_todas">
                            Todos
                        </label>
                    </div>
                    <div class="form-check col-sm-2">
                        <input #Entrada class="form-check-input" type="radio" [(ngModel)]="checkStock"
                            (click)="buscarProducto(Entrada.value,id)" id="p_Entrada" value="Entrada">
                        <label class="form-check-label" for="p_Entrada">
                            Entradas
                        </label>
                    </div>
                    <div class="form-check col-sm-2">
                        <input #Salida class="form-check-input" type="radio" [(ngModel)]="checkStock"
                            (click)="buscarProducto(Salida.value,id)" id="p_Salida" value="Salida">
                        <label class="form-check-label" for="p_Salida">
                            Salidas
                        </label>
                    </div>

                    <div class="col-sm-3">
                        <div class="text-right">
                            <button [routerLink]="['/almacen']" type="button"
                                class="btn waves-effect waves-light btn-rounded btn-danger">
                                <i class="fa fa-arrow-left"> Regresar</i>
                            </button>
                        </div>
                    </div>

                </div>
                <hr>
                <div class="col-lg-12 row">
                    <div class="col-lg-9">
                        <div class="table-responsive">
                            <table *ngIf="!cargando" class="table table-striped animated fadeIn faster" width="100%"
                                cellspacing="0">
                                <thead class="bg-theme">
                                    <tr>
                                        <th>
                                            <p class="font-weight-bold text-white">Movimiento</p>
                                        </th>
                                        <th>
                                            <p class="font-weight-bold text-white">Folio</p>
                                        </th>
                                        <th>
                                            <p class="font-weight-bold text-white">talla</p>
                                        </th>
                                        <th>
                                            <p class="font-weight-bold text-white">Tipo entrega</p>
                                        </th>
                                        <th>
                                            <p class="font-weight-bold text-white">Cantidad</p>
                                        </th>
                                        <th>
                                            <p class="font-weight-bold text-white">Estado</p>
                                        </th>
                                        <th>
                                            <p class="font-weight-bold text-white">Fecha entrega</p>
                                        </th>
                                        <th>
                                            <p class="font-weight-bold text-white">Color</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let p of uniforme | paginate: config; let i = index">
                                        <td>{{ p.movimiento | lowercase }}</td>
                                        <td>{{ p.folioPapeleta }}</td>
                                        <td>{{ p.talla }} </td>
                                        <td>{{ p.descripcion }} </td>
                                        <td>{{ p.cantidad }} </td>
                                        <td>{{ p.estado }} </td>
                                        <td>{{ p.fechaEntrega | date }} </td>
                                        <td>{{ p.color }} </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div align="right">
                                <pagination-controls (pageChange)="pageChanged($event)" autoHide="true"
                                    responsivo="true" directionLinks="true" previousLabel="Anterior"
                                    nextLabel="Siguiente"></pagination-controls>
                            </div>
                        </div>
                        <div *ngIf="cargando && result == false"
                            class="alert alert-info text-center mt-3 animated fadeIn faster">
                            <h4 clas="alert-heading">Cargando</h4>
                            <p>
                                <i class="fa fa-sync-alt fa-spin fa 2x"></i>
                            </p>
                            <p class="mb-0">
                                Espere por favor
                            </p>
                        </div>
                        <div *ngIf="!cargando && result == true" class="alert alert-warning text-center mt-3">
                            <h4 clas="alert-heading">No Se encontraron registros</h4>
                            <p>
                                <i class="fa fa-exclamation fa 2x"></i>
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card border border-secondary">
                            <div class="card-header bg-primary">
                                <h4 class="card-title text-center text-white">Precio actual de {{tipoUniforme}}</h4>
                            </div>
                            <div class="card-body">
                                <form (ngSubmit)="actualizar( f )" #f="ngForm">
                                    <label class="control-label">Categoria</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control" name="categoria" placeholder="Uniforme"
                                            disabled>
                                    </div>
                                    <label class="control-label">precio</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control" [(ngModel)]="tipoUniformes.precio" name="precio" required>
                                    </div>
                                    <div class="form-actions" align="right">
                                        <button type="submit" class="btn btn-primary mr-1"> <i class="fa fa-check"></i>
                                            Actualizar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>