import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from 'src/app/config/config';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { FormGroup, NgForm } from '@angular/forms';
import { CategoriaAlmacen } from 'src/app/almacen/models/categoriaAlmacen';
import { CategoriaUniformeModel } from '../../models/categoria-unifome';

@Injectable({
  providedIn: 'root'
})
export class AlmacenService {
  token = localStorage.getItem('token');
  constructor( private http: HttpClient ) { }

  getStock(token: string, tipo: string) {
    return this.http.post(URL_SERVICIOS + '/almacen/stock/uniforme?token=' + token,
    {
      tipo
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  getReporteSemanal(token: string) {
    return this.http.post(URL_SERVICIOS + '/almacen/stock/estadistico/semana/uniforme?token=' + token,
    {
      token
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  getReporteSemanalID(token: string, id: string) {
    return this.http.post(URL_SERVICIOS + '/almacen/stock/estadistico/semana/uniforme/detalle?token=' + token,
    {
      id
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  getCategoriaUniforme(token: string, tipo: string) {
    return this.http.post(URL_SERVICIOS + '/almacen/stock/categoriaUniforme?token=' + token,
    {
      tipo
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  actualizarCategoriaUniforme( token: string, categoria: CategoriaUniformeModel) {
    const createFact = {
      precio: categoria.precio,
      tipo: categoria.tipoUniforme
    };
    return this.http.put(URL_SERVICIOS + '/almacen/stock/categoriaUniforme?token=' + this.token, createFact)
    .pipe(
      map( (resp: any) => {
        return categoria.tipoUniforme;
      })
    );
  }



  getUniformeGrafico(token: string) {
    return this.http.post(URL_SERVICIOS + '/almacen/stock/estadistico/uniforme/grafico?token=' + token,
    {})
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  getTipoAlmacen() {
    return this.http.post(URL_SERVICIOS + '/almacen/tipo-almacen?token=' + this.token,
    {
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  getTipoID(id: string) {
    return this.http.post(URL_SERVICIOS + '/almacen/tipo-almacenID?token=' + this.token,
    {
      id
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  getProductoAsignado(token: string, cveEmp: string) {
    return this.http.post(URL_SERVICIOS + '/producto/asignado?token=' + token,
    {
      claveEmpresa: cveEmp
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  getStockEstadistico(token: string, tipo: string) {
    return this.http.post(URL_SERVICIOS + '/almacen/stock/estadistico/uniforme?token=' + token,
    {
      tipo
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  getProductoID(token: string, id: string) {
    return this.http.post(URL_SERVICIOS + '/producto/productoID?token=' + token,
    {
      id
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  getUniformeSearch(token: string, termino: string, tipo: string) {
    return this.http.post(URL_SERVICIOS + '/almacen/stock/uniforme/uniformeSearch?token=' + token,
    {
      termino,
      tipo
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }


  entradaDeUniforme( form: FormGroup, token: string) {
    const createFact = {
      form

    };
    return this.http.post(URL_SERVICIOS + '/almacen/uniforme/insertUniforme?token=' + token, form)
    .pipe(
      map( (resp: any) => {
        return resp;
      })
    );
  }

 /*  actualizarProducto( camisa: CamisaModel, token: string ) {
    const updateProducto = {
      identificador: camisa.identificador,
      modelo: camisa.modelo,
      procesador: camisa.procesador,
      ram: camisa.ram,
      discoDuro: camisa.discoDuro,
      sistemaOperativo: camisa.sistemaOperativo,
      marca: camisa.marca,
      claveTipoProducto: camisa.claveTipoProducto,
      token,
      cveEmp: localStorage.getItem('cveEmp'),
      observacion: camisa.observacion,
      claveProducto: camisa.claveProducto,
      status: camisa.status
    };
    return this.http.put(`${ URL_SERVICIOS }/producto/updateProducto?token=` + token, updateProducto);
  }*/

  crearTipoAlmacen( categoria: CategoriaAlmacen) {
    const createFact = {
      tipo: categoria.tipo
    };
    return this.http.post(URL_SERVICIOS + '/almacen/insertTipo-almacen?token=' + this.token, createFact)
    .pipe(
      map( (resp: any) => {
        return categoria;
      })
    );
  }

  actualizarTipoAlmacen( categoria: CategoriaAlmacen) {
    const updateTipoAlmacen = {
      id: categoria.idTipoArticulo,
      tipo: categoria.tipo
    };
    return this.http.put(`${ URL_SERVICIOS }/almacen/updateTipo-almacen?token=` + this.token, updateTipoAlmacen);
  }
}
