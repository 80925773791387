<div class="cols">
    <div class="col" ontouchstart="this.classList.toggle('hover');">
        <div class="container">
            <div class="front" style="background-image: url(https://unsplash.it/505/505/)">
                <div class="inner">
                    <p>Uniformes</p>
                </div>
            </div>
            <div class="back">
                <div class="inner">
                    <button class="btn btn-secondary btn-block" [routerLink]="['/uniformes/1']">Pantalon</button>
                    <button class="btn btn-secondary btn-block" [routerLink]="['/uniformes/2']">Camisa</button>
                    <button class="btn btn-secondary btn-block" [routerLink]="['/uniformes/3']">Chamarra</button>
                    <button class="btn btn-secondary btn-block" [routerLink]="['/uniformes/4']">Gorra</button>
                    <button class="btn btn-secondary btn-block" [routerLink]="['/uniformes/5']">Chaleco</button>
                    <button class="btn btn-secondary btn-block" [routerLink]="['/uniformes/6']">Zapato</button>
                    <button class="btn btn-secondary btn-block" [routerLink]="['/uniformes/7']">Corbata</button>
                    <button class="btn btn-secondary btn-block" [routerLink]="['/uniformes/8']">Lente</button>
                    <button class="btn btn-secondary btn-block" [routerLink]="['/uniformes/9']">Cubrebocas</button>
                    <button class="btn btn-secondary btn-block" [routerLink]="['/uniformes/10']">Careta</button>
                    <!--button class="btn btn-secondary btn-block" [routerLink]="['/uniformes/11']">Antidoping</button-->
                </div>
            </div>
        </div>
    </div>
    <div class="col" ontouchstart="this.classList.toggle('hover');">
        <div class="container">
            <div class="front" style="background-image: url(https://unsplash.it/506/506/)">
                <div class="inner">
                    <p>Herramienta</p>
                </div>
            </div>
            <div class="back">
                <div class="inner">
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum rctetur adipisicing elit. Alias cum rctetur adipisicing elit. Alias cum rctetur adipisicing elit. Alias cum repellat velit quae suscipit c.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="col" ontouchstart="this.classList.toggle('hover');">
        <div class="container">
            <div class="front" style="background-image: url(https://unsplash.it/507/507/)">
                <div class="inner">
                    <p>EPP</p>
                </div>
            </div>
            <div class="back">
                <div class="inner">
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="col" ontouchstart="this.classList.toggle('hover');">
        <div class="container">
            <div class="front" style="background-image: url(https://unsplash.it/508/508/)">
                <div class="inner">
                    <p>Materiales</p>
                </div>
            </div>
            <div class="back">
                <div class="inner">
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="cols">
    <div class="col" ontouchstart="this.classList.toggle('hover');">
        <div class="container">
            <div class="front" style="background-image: url(https://unsplash.it/509/509/)">
                <div class="inner">
                    <p>Papelería</p>
                </div>
            </div>
            <div class="back">
                <div class="inner">
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="col" ontouchstart="this.classList.toggle('hover');">
        <div class="container">
            <div class="front" style="background-image: url(https://unsplash.it/510/510/)">
                <div class="inner">
                    <p>Equipo de prueba</p>
                </div>
            </div>
            <div class="back">
                <div class="inner">
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="col" ontouchstart="this.classList.toggle('hover');">
        <div class="container">
            <div class="front" style="background-image: url(https://unsplash.it/511/511/)">
                <div class="inner">
                    <p>Equipo Electrónicos</p>
                </div>
            </div>
            <div class="back">
                <div class="inner">
                    <ul>
                        <li>Equipos de computo
                            <button class="btn btn-primary" [routerLink]="['/productos']">ver</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="col" ontouchstart="this.classList.toggle('hover');">
        <div class="container">
            <div class="front" style="background-image: url(https://unsplash.it/512/512/)">
                <div class="inner">
                    <p>Activos Fijos</p>
                </div>
            </div>
            <div class="back">
                <div class="inner">
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias cum repellat velit quae suscipit c.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>