import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { PlantillaService } from '../../../services/plantilla/plantilla.service';
import { ActivatedRoute } from '@angular/router';
import { UniformeModel } from '../../models/uniforme.model';

@Component({
  selector: 'app-asignaciones-uniformes',
  templateUrl: './asignaciones-uniformes.component.html',
  styleUrls: ['./asignaciones-uniformes.component.css']
})
export class AsignacionesUniformesComponent implements OnInit {

// vacante: VacanteModel[] = [];
uniforme: UniformeModel[] = [];
nombre: string;
cargando = false;
result = false;
token: string;
config: any;
id: any;
costo: string;
servicios: string;
constructor(public plantillaService: PlantillaService, private route: ActivatedRoute) { }

 pageChanged( event ) {
  this.config.currentPage = event;
}

ngOnInit() {
  this.id = this.route.snapshot.paramMap.get('id');
  this.config = {
    itemsPerPage: 10,
    currentPage: 1
  };

  this.cargando = true;
  this.token = localStorage.getItem('token');
  this.cargarPlantilla(this.id);
}

cargarPlantilla(id: string) {
  this.plantillaService.getAsignacionUniforme(this.token, id)
  .subscribe( (resp: any) => {
    if (resp.response.length > 0) {
       this.uniforme = resp.response;
       this.nombre = resp.response['0'].nombre + resp.response['0'].apellido;
    } else {
      this.result = true;
    }
    this.cargando = false;
  });
}

borrarVacante( vacante: string, i: number) {
  Swal.fire({
    title: '¿Está Seguro?',
    text: `Esta Seguro que desea borrar el uniforme entregado?`,
    type: 'question',
    showConfirmButton: true,
    showCancelButton: true
  }).then( resp => {
    if (resp.value) {
      this.plantillaService.borrarAsignacionUniforme(vacante, this.token)
      .subscribe( () => {
        this.uniforme.splice( i, 1 );
        this.cargarPlantilla(this.id);
      });
    }
  });
}
}
