<form (ngSubmit)="guardar( f )" #f="ngForm">

    <div *ngIf="!accionUpdate" class="alert alert-secondary" role="alert">
        <p class="font-weight-bold text-center">SERVICIO O VACANTE A CUBRIR</p>
    </div>

    <div *ngIf="!accionUpdate" class="form-group">
        <label class="col-form-label">SELECCIONE EL SERVICIO A CUBRIR</label>
        <select name="servicio" [(ngModel)]="empleado.servicio" class="form-control" required>
            <option *ngFor="let cliente of servicio | filtroCliente" [value]="cliente.claveCliente">{{ cliente.nombreEmpresa }}</option>                      
        </select>
    </div>

    <div class="alert alert-secondary" role="alert">
        <p class="font-weight-bold text-center">DATOS PERSONALES</p>
    </div>

    <div class="form-group">
        <label>Nombre</label>
        <input type="text" class="form-control" name="nombre" [(ngModel)]="empleado.nombre" placeholder="Nombre del empleado" required/>
    </div>

    <div class="form-group">
        <label>Apellido</label>
        <input type="text" class="form-control" name="apellido" [(ngModel)]="empleado.apellido" placeholder="Apellido del guardia" required/>
    </div>

    <div class="form-group">
        <label>RFC</label>
        <input type="text" class="form-control" name="rfc" [(ngModel)]="empleado.rfc" placeholder="RFC del guardia" required/>
    </div>

    <div class="form-group">
        <label>Teléfono</label>
        <input type="text" class="form-control" name="telefono" [(ngModel)]="empleado.telefono" placeholder="Teléfono del guardia" required/>
    </div>

    <div class="form-group">
        <label>Correo Electronico</label>
        <input type="text" class="form-control" name="correoElectronico" [(ngModel)]="empleado.correoElectronico" placeholder="Ingrese el correo Electronico del guardia" required/>
    </div>

    <div class="form-group">
        <label>Fecha de nacimiento</label>
        <input type="date" class="form-control" name="fechaDeNacimiento" [(ngModel)]="empleado.fechaDeNacimiento" id="date-format" required/>
    </div>

    <div class="form-group">
        <label>Lugar de nacimiento</label>
        <input type="text" class="form-control" name="lugarNacimiento" [(ngModel)]="empleado.lugarDeNacimiento" placeholder="Ingrese lugar de nacimiento" required/>
    </div>

    <div class="form-group">
        <label>CURP</label>
        <input type="text" class="form-control" name="curp" [(ngModel)]="empleado.curp" placeholder="Ingrese el curp" required/>
    </div>

    <div class="form-group">
        <label>NNS</label>
        <input type="text" class="form-control" name="nns" [(ngModel)]="empleado.nns" placeholder="Ingrese el número de seguro social" required/>
    </div>

    <div class="form-group">
        <label>Clave de Elector</label>
        <input type="text" class="form-control" name="cveElector" [(ngModel)]="empleado.claveDeElector" placeholder="Ingrese la clave del elector" required/>
    </div>

    <div class="form-group">
        <label>Nivel de Estudio</label>
        <select name="nivelEstudio" [(ngModel)]="empleado.claveNivelDeEstudios" class="form-control">

            </select>
    </div>

    <div class="form-group">
        <label>Licencia de conducir</label>
        <input type="text" class="form-control" name="licConducir" [(ngModel)]="empleado.licenciaDeConducir" placeholder="Licencia de  Conducir" required/>
    </div>
    <div class="form-group">
        <label>Cartilla Militar</label>
        <input type="text" class="form-control" name="cartillaMilitar" [(ngModel)]="empleado.cartillaMilitar" placeholder="Ingrese la Cartilla Militar" required/>
    </div>

    <div class="alert alert-secondary" role="alert">
        <p class="font-weight-bold text-center">DIRECCIÓN DE EMPLEADO</p>
    </div>

    <div class="form-group">
        <label>Calle</label>
        <input type="text" class="form-control" name="calle" [(ngModel)]="empleado.calle" placeholder="Ingrese la calle" required/>
    </div>
    <div class="form-group">
        <label>número</label>
        <input type="text" class="form-control" name="numero" [(ngModel)]="empleado.numero" placeholder="Ingrese el numero" required/>
    </div>

    <div class="form-group">
        <label>Colonia</label>
        <input type="text" class="form-control" name="colonia" [(ngModel)]="empleado.colonia" placeholder="Ingrese la colonia" required/>
    </div>

    <div class="form-group">
        <label>Entre que Calles</label>
        <input type="text" class="form-control" name="entreQueCalles" [(ngModel)]="empleado.entreQueCalles" placeholder="Ingrese entre que calles se ubica" required/>
    </div>

    <div class="form-group">
        <label>Codigo Postal</label>
        <input type="text" class="form-control" name="cp" [(ngModel)]="empleado.cp" placeholder="Ingrese el codigo postal" required/>
    </div>

    <div class="form-group">
        <label>Delegación o Municipio</label>
        <input type="text" class="form-control" name="delegacionMunicipio" [(ngModel)]="empleado.delegacionMunicipio" placeholder="Ingrese la Delegación o Municipio" required/>
    </div>

    <div class="form-group">
        <label>Estado</label>
        <input type="text" class="form-control" name="estado" [(ngModel)]="empleado.estado" placeholder="Ingrese el Estado" required/>
    </div>

    <div class="alert alert-secondary" role="alert">
        <p class="font-weight-bold text-center">EN CASO DE EMERGENCIA AVISAR A</p>
    </div>

    <div class="form-group">
        <label>Nombre</label>
        <input type="text" class="form-control" name="nombreEmerge" [(ngModel)]="empleado.nombreEmerge" placeholder="Ingrese el nombre" required/>
    </div>

    <div class="form-group">
        <label>Parentesco</label>
        <input type="text" class="form-control" name="parentescoEmerge" [(ngModel)]="empleado.parentescoEmerge" placeholder="Ingrese el Parentesco" required/>
    </div>

    <div class="form-group">
        <label>Telefono</label>
        <input type="text" class="form-control" name="telefonoEmerge" [(ngModel)]="empleado.telefonoEmerge" placeholder="Ingrese el telefono" required/>
    </div>

    <div class="form-group">
        <label>Alergias</label>
        <input type="text" class="form-control" name="alergiasEmerge" [(ngModel)]="empleado.alergiasEmerge" placeholder="Ingrese las alergias" required/>
    </div>
    <div class="form-group">
        <label>Tipo Sanguinio</label>
        <input type="text" class="form-control" name="tpSanguinioEmerge" [(ngModel)]="empleado.tpSanguinioEmerge" placeholder="Ingrese el tipo Sanguinio" required/>
    </div>

    <div class="alert alert-secondary" role="alert">
        <p class="font-weight-bold text-center">BENEFICIARIO</p>
    </div>

    <div class="form-group">
        <label>Nombre</label>
        <input type="text" class="form-control" name="nombreBeneficiario" [(ngModel)]="empleado.nombreBeneficiario" placeholder="Ingrese el nombre" required/>
    </div>

    <div class="form-group">
        <label>Parentesco</label>
        <input type="text" class="form-control" name="parentescoBeneficiario" [(ngModel)]="empleado.parentescoBeneficiario" placeholder="Ingrese el Parentesco" required/>
    </div>

    <div class="form-group">
        <label>Telefono</label>
        <input type="text" class="form-control" name="telefonoBeneficiario" [(ngModel)]="empleado.telefonoBeneficiario" placeholder="Ingrese el telefono" required/>
    </div>

    <div class="alert alert-secondary" role="alert">
        <p class="font-weight-bold text-center">ULTIMO EMPLEO</p>
    </div>

    <div class="form-group">
        <label>Nombre de ultimo Empleo</label>
        <input type="text" class="form-control" name="ultimoEmpleo" [(ngModel)]="empleado.ultimoEmpleo" placeholder="Ingrese el ultimo empleo" required/>
    </div>

    <div class="form-group">
        <label>Antiguedad</label>
        <input type="text" class="form-control" name="antiguedadUltimoEmpleo" [(ngModel)]="empleado.antiguedadUltimoEmpleo" placeholder="Ingrese la antiguedad" required/>
    </div>

    <div class="form-group">
        <label>Puesto</label>
        <input type="text" class="form-control" name="puestoUltimoEmpleo" [(ngModel)]="empleado.puestoUltimoEmpleo" placeholder="Ingrese el puesto" required/>
    </div>

    <div class="form-group">
        <label>Motivo de separación</label>
        <input type="text" class="form-control" name="motivoSeparacionUltimoEmpleo" [(ngModel)]="empleado.motivoSeparacionUltimoEmpleo" placeholder="Ingrese el motivo de separación" required/>
    </div>

    <div class="alert alert-secondary" role="alert">
        <p class="font-weight-bold text-center">REGISTRO LABORAL</p>
    </div>

    <input type='hidden' name='numEmpleado' [(ngModel)]="empleado.numEmpleado" value='na' />

    <div class="form-group">
        <label>Fecha de ingreso</label>
        <input class="form-control" type="date" name="fechaIngreso" [(ngModel)]="empleado.fechaIngreso" required/>
    </div>

    <div class="form-group">
        <label>Puesto</label>
        <input type="text" class="form-control" name="puestoEmpleo" [(ngModel)]="empleado.puesto" placeholder="Ingrese el puesto" required/>
    </div>

    <div class="alert alert-secondary" role="alert">
        <p class="font-weight-bold text-center">DOCUMENTOS ENTREGADOS</p>
    </div>

    <div class="form-group">
        <div class="form-check">
            <input type="checkbox" class="form-check-input" id="IFE">
            <label class="form-check-label" for="IFE">IFE</label>
        </div>
        <div class="form-check">
            <input type="checkbox" class="form-check-input" id="CURP">
            <label class="form-check-label" for="CURP">CURP</label>
        </div>
        <div class="form-check">
            <input type="checkbox" class="form-check-input" id="NSS">
            <label class="form-check-label" for="NSS">NSS</label>
        </div>
        <div class="form-check">
            <input type="checkbox" class="form-check-input" id="ACTADENACIMIENTO">
            <label class="form-check-label" for="ACTADENACIMIENTO"> ACTA DE NACIMIENTO</label>
        </div>
        <div class="form-check">
            <input type="checkbox" class="form-check-input" id="CARTILLAMILITAR">
            <label class="form-check-label" for="CARTILLAMILITAR">CARTILLA MILITAR</label>
        </div>
        <div class="form-check">
            <input type="checkbox" class="form-check-input" id="COMPROBANTEDEDOMICILIO">
            <label class="form-check-label" for="COMPROBANTEDEDOMICILIO">COMPROBANTE DE DOMICILIO</label>
        </div>
        <div class="form-check">
            <input type="checkbox" class="form-check-input" id="COMPROBANTEDEESTUDIOS">
            <label class="form-check-label" for="COMPROBANTEDEESTUDIOS">COMPROBANTE DE ESTUDIOS</label>
        </div>
        <div class="form-check">
            <input type="checkbox" class="form-check-input" id="ANTECEDENTESNOPENALES">
            <label class="form-check-label" for="ANTECEDENTESNOPENALES">ANTECEDENTES NO PENALES</label>
        </div>
    </div>
    <button name='insert' id='insert' class="btn width-110 btn-primary">Guardar Registro</button>
</form>