import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'folioPapeleta'
})
export class FolioPapeletaPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    if (arg === '') { return value; }
    const resultFolioPapeleta = [];
    for (const folio of value ) {
      if (folio.folioPapeleta.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
        resultFolioPapeleta.push(folio);
      }
      
    }

    return resultFolioPapeleta;
  }

}
