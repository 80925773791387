<div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header bg-dark text-center">
                <h4 class="modal-title font-weight-bold text-white text-center" id="myLargeModalLabel">{{ nombreEmpresa | uppercase }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <!-- ---------------------------------------------------------------------------------------------------------------- -->
                <div *ngIf="!accionUpdate" class="table-responsive">
                    <div class="card">
                        <button type="button" class="btn btn-dark">Enviar observación</button>
                    </div>
                    <table class="table table-bordered animated fadeIn faster" width="100%" cellspacing="0">
                        <thead class="bg-dark">
                            <tr>
                                <th>
                                    <p class="font-weight-bold text-white">#</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Imagen</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Nombre</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Puesto</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Servicio</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">inicio</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Zona</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Turno</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let s of servicio; let i = index">
                                <td>{{ i+1 }}</td>
                                <td> <img [src]='s.urlphoto' width='80px' height='80px' /></td>
                                <td>{{ s.nombre +' '+ s.apellido | titlecase }}</td>
                                <td>{{ s.puesto | titlecase }}</td>
                                <td>{{ s.servicio | titlecase }}</td>
                                <td>{{ s.fechaInicio | date }}</td>
                                <td>{{ s.zona | lowercase }} </td>
                                <td>{{ s.turno | lowercase }} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!------------------------------------------------------------------------------------------------------------------------>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-dark waves-effect text-left" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <input #input (keyup)="buscarServicioCliente( input.value )" name="termino" type="text" class="form-control" placeholder="Buscar servicio...">
            </div>
        </div>
    </div>
</div>
<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div *ngIf="!cargando" class="row">
                    <div *ngFor="let f of clienteServicio" class="col-md-3">
                        <div class="card">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="social-widget">
                                        <div class="soc-header border border-secondary bg-dark">

                                            <h6 class="font-weight-bold card-title text-light">Nombre comercial: {{ f.nombreComercial | lowercase }}</h6>
                                            <h6 class="font-weight-bold card-title text-light">Empresa: {{ f.nombreEmpresa | lowercase }}</h6>
                                            <button (click)="detalleInfo(f.claveCliente,'cubierto')" class="btn btn-outline-secondary" data-toggle="modal" data-target=".bs-example-modal-lg">        
                                                <img [src]='f.urlphoto' width='100px' height='100px' />
                                            </button>
                                            <h5 class="font-weight-bold text-light m-t-10">Consultar</h5>
                                        </div>
                                        <div class="bg-secondary">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <button class="btn btn-primary">Registrar</button>
                                                </div>
                                                <div class="col-lg-6" align="right">
                                                    <button class="btn btn-primary">Editar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="cargando && result == false" class="alert alert-info text-center mt-3 animated fadeIn faster">
                    <h4 clas="alert-heading">Cargando</h4>
                    <p>
                        <i class="fa fa-sync-alt fa-spin fa 2x"></i>
                    </p>
                    <p class="mb-0">
                        Espere por favor
                    </p>
                </div>
                <div *ngIf="!cargando && result == true" class="alert alert-warning text-center mt-3">
                    <h4 clas="alert-heading">No Se encontraron registros</h4>
                    <p>
                        <i class="fa fa-exclamation fa 2x"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>