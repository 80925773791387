import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { VacanteModel } from 'src/app/models/vacante.model';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ContratacionService } from 'src/app/services/contratacion/contratacion.service';
import { AlmacenService } from 'src/app/almacen/services/almacen/almacen.service';

@Component({
  selector: 'app-entrada-uniforme',
  templateUrl: './entrada-uniforme.component.html',
  styleUrls: ['./entrada-uniforme.component.css']
})
export class EntradaUniformeComponent implements OnInit {

  turno: VacanteModel[] = [];
  token: string;
  public form: FormGroup;
  public contactPantalon: FormArray;
  public contactCamisa: FormArray;
  public contactChamarra: FormArray;
  public contactGorra: FormArray;
  public contactChaleco: FormArray;
  public contactZapato: FormArray;
  public contactSudadera: FormArray;
 // public contactGuardiaSeguridad: FormArray;
  id: any;

  constructor(private fb: FormBuilder, private almacenService: AlmacenService, private router: Router,
              public contratacionService: ContratacionService) {}

  // returns all form groups under contacts
  get pantalonFormGroup() {
    return this.form.get('pantalon') as FormArray;
  }
  get camisaFormGroup() {
    return this.form.get('camisa') as FormArray;
  }
  get chamarraFormGroup() {
    return this.form.get('chamarra') as FormArray;
  }
  get gorraFormGroup() {
    return this.form.get('gorra') as FormArray;
  }
  get zapatoFormGroup() {
    return this.form.get('zapato') as FormArray;
  }
  get chalecoFormGroup() {
    return this.form.get('chaleco') as FormArray;
  }

  get sudaderaFormGroup() {
    return this.form.get('sudadera') as FormArray;
  }

  ngOnInit() {
    this.token = localStorage.getItem('token');

    this.form = this.fb.group({
      pantalon: this.fb.array([this.crearPantalon()]),
      camisa: this.fb.array([this.crearCamisa()]),
      chamarra: this.fb.array([this.crearChammarra()]),
      gorra: this.fb.array([this.crearGorra()]),
      zapato: this.fb.array([this.crearZapato()]),
      chaleco: this.fb.array([this.crearChaleco()]),
      sudadera: this.fb.array([this.crearSudadera()])
    });

    // set contactPantalon to this field
    this.contactPantalon = this.form.get('pantalon') as FormArray;
    this.contactCamisa = this.form.get('camisa') as FormArray;
    this.contactChamarra = this.form.get('chamarra') as FormArray;
    this.contactGorra = this.form.get('gorra') as FormArray;
    this.contactChaleco = this.form.get('chaleco') as FormArray;
    this.contactZapato = this.form.get('zapato') as FormArray;
    this.contactSudadera = this.form.get('sudadera') as FormArray;

    this.quitarPersona(0, 'pantalon');
    this.quitarPersona(0, 'camisa');
    this.quitarPersona(0, 'chamarra');
    this.quitarPersona(0, 'gorra');
    this.quitarPersona(0, 'chaleco');
    this.quitarPersona(0, 'zapato');
    this.quitarPersona(0, 'sudadera');
  }


   // contact formgroup
   crearPantalon(): FormGroup {
    return this.fb.group({
      idPantalon: ['1', Validators.compose([Validators.required])], // i.e Email, Phone
      cantidadPantalon: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      descripcionPantalon: ['na', Validators.compose([Validators.required])], // i.e. Home, Office
      tallaPantalon: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      estadoPantalon: ['N', Validators.compose([Validators.required])], // i.e. Home, Office
      fechaEntregaPantalon: ['', Validators.compose([Validators.required])],
    });
  }

  crearGorra(): FormGroup {
    return this.fb.group({
      idGorra: ['4', Validators.compose([Validators.required])], // i.e Email, Phone
      cantidadGorra: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      descripcionGorra: ['na', Validators.compose([Validators.required])], // i.e. Home, Office
      tallaGorra: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      estadoGorra: ['Negro', Validators.compose([Validators.required])], // i.e. Home, Office
      fechaEntregaGorra: ['', Validators.compose([Validators.required])],

    });
  }


  crearCamisa(): FormGroup {
    return this.fb.group({
      idCamisa: ['2', Validators.compose([Validators.required])], // i.e Email, Phone
      cantidadCamisa: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      descripcionCamisa: ['na', Validators.compose([Validators.required])], // i.e. Home, Office
      tallaCamisa: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      estadoCamisa: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      fechaEntregaCamisa: ['', Validators.compose([Validators.required])],

    });
  }

  crearChammarra(): FormGroup {
    return this.fb.group({
      idChamarra: ['3', Validators.compose([Validators.required])], // i.e Email, Phone
      cantidadChamarra: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      descripcionChamarra: ['na', Validators.compose([Validators.required])], // i.e. Home, Office
      tallaChamarra: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      estadoChamarra: ['Negro', Validators.compose([Validators.required])], // i.e. Home, Office
      fechaEntregaChamarra: ['', Validators.compose([Validators.required])],

    });
  }

  crearZapato(): FormGroup {
    return this.fb.group({
      idZapato: ['6', Validators.compose([Validators.required])], // i.e Email, Phone
      cantidadZapato: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      descripcionZapato: ['na', Validators.compose([Validators.required])], // i.e. Home, Office
      tallaZapato: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      estadoZapato: ['N', Validators.compose([Validators.required])], // i.e. Home, Office
      fechaEntregaZapato: ['', Validators.compose([Validators.required])],

    });
  }

  crearChaleco(): FormGroup {
    return this.fb.group({
      idChaleco: ['5', Validators.compose([Validators.required])], // i.e Email, Phone
      cantidadChaleco: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      descripcionChaleco: ['na', Validators.compose([Validators.required])], // i.e. Home, Office
      tallaChaleco: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      estadoChaleco: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      fechaEntregaChaleco: ['', Validators.compose([Validators.required])],

    });
  }

  crearRondinero(): FormGroup {
    return this.fb.group({
      turnoRondinero: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      sueldoRondinero: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      costoRondinero: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      razonSocialRondinero: ['', Validators.compose([Validators.required])] // i.e. Home, Office
    });
  }

  crearGuardiaSeguridad(): FormGroup {
    return this.fb.group({
      turnoGuardiaSeguridad: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      sueldoGuardiaSeguridad: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      costoGuardiaSeguridad: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      razonSocialGuardiaSeguridad: ['', Validators.compose([Validators.required])] // i.e. Home, Office
    });
  }
  crearSudadera(): FormGroup {
    return this.fb.group({
      idSudadera: ['7', Validators.compose([Validators.required])], // i.e Email, Phone
      cantidadSudadera: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      descripcionSudadera: ['na', Validators.compose([Validators.required])], // i.e. Home, Office
      tallaSudadera: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      estadoSudadera: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      fechaEntregaSudadera: ['', Validators.compose([Validators.required])],
    });
  }


  // add a contact form group
  AgregarPersona(puesto: string) {
     if (puesto === 'pantalon') {
        this.contactPantalon.push(this.crearPantalon());
       } else {
               if (puesto === 'camisa') {
                   this.contactCamisa.push(this.crearCamisa());

                  } else {
                          if (puesto === 'chamarra') {
                            this.contactChamarra.push(this.crearChammarra());

                          } else {
                                 if (puesto === 'gorra') {
                                    this.contactGorra.push(this.crearGorra());
                                  } else {
                                        if (puesto === 'zapato') {
                                           this.contactZapato.push(this.crearZapato());
                                            } else {
                                                   if (puesto === 'chaleco') {
                                                      this.contactChaleco.push(this.crearChaleco());
                                                      } else {
                                                            if (puesto === 'sudadera') {
                                                                this.contactSudadera.push(this.crearSudadera());
                                                                } else {
                                                                       if (puesto === 'guardiaSeguridad') {
                                                                        //  this.contactGuardiaSeguridad.push(this.crearGuardiaSeguridad());
                                                                          } else {

                                                                                 }
                                                                       }

                                                             }

                                                   }
                                          }
                                 }
                          }
               }
  }

 // remove contact from group
 quitarPersona(index, puesto: string) {
  if (puesto === 'pantalon') {
    this.contactPantalon.removeAt(index);
  } else {
       if (puesto === 'camisa') {
       this.contactCamisa.removeAt(index);

       } else {
         if (puesto === 'chamarra') {
           this.contactChamarra.removeAt(index);

         } else {
          if (puesto === 'gorra') {
            this.contactGorra.removeAt(index);

          } else {
            if (puesto === 'zapato') {
              this.contactZapato.removeAt(index);

            } else {
              if (puesto === 'chaleco') {
                this.contactChaleco.removeAt(index);

              } else {
                if (puesto === 'sudadera') {
                  this.contactSudadera.removeAt(index);

                 } else {
                  if (puesto === 'guardiaSeguridad') {
                   // this.contactGuardiaSeguridad.removeAt(index);

                   } else {
                          }
                        }
              }
            }

          }

         }

       }
  }
}

  // get the formgroup under contacts form array
  getPantalonFormGroup(index): FormGroup {
    const formGroup = this.contactPantalon.controls[index] as FormGroup;
    return formGroup;
  }

  getCamisaFormGroup(index): FormGroup {
    const formGroup = this.contactCamisa.controls[index] as FormGroup;
    return formGroup;
  }

  getChamarraFormGroup(index): FormGroup {
    const formGroup = this.contactChamarra.controls[index] as FormGroup;
    return formGroup;
  }

  getGorraFormGroup(index): FormGroup {
    const formGroup = this.contactGorra.controls[index] as FormGroup;
    return formGroup;
  }

  getZapatoFormGroup(index): FormGroup {
    const formGroup = this.contactZapato.controls[index] as FormGroup;
    return formGroup;
  }

  getChalecoFormGroup(index): FormGroup {
    const formGroup = this.contactChaleco.controls[index] as FormGroup;
    return formGroup;
  }

  getSudaderaFormGroup(index): FormGroup {
    const formGroup = this.contactSudadera.controls[index] as FormGroup;
    return formGroup;
  }
/*
  getContactsFormGroupRondinero(index): FormGroup {
   // const formGroup = this.contactRondinero.controls[index] as FormGroup;
    return formGroup;
  }*/
/*
  getContactsFormGroupGuardiaSeguridad(index): FormGroup {
    const formGroup = this.contactFormGroupGuardiaSeguridad.controls[index] as FormGroup;
    return formGroup;
  }*/

  // method triggered when form is submitted
  submit() {
    if (!this.form.valid) {
      Swal.fire({
        title: 'Falta info por llenar',
        text: 'completa la información',
        type: 'warning'
      });
      return ;
    } else {
         console.log(this.form.value);
         this.guardar(this.form.value);
    }
  }

  guardar(form: FormGroup) {

    if (form.invalid) {
      Swal.fire({
        title: 'Falta info por llena',
        text: 'completa la información',
        type: 'warning'
      });
      return ;
    }
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    });
    Swal.showLoading();
    let peticion: Observable<any>;
    /*if (this.producto.claveProducto) {
      // peticion = this.productoService.actualizarProducto(this.producto, this.token);
    } else {
      peticion = this.productoService.crearProducto(this.producto, this.token);
    }*/
    peticion = this.almacenService.entradaDeUniforme(form, this.token);

    peticion.subscribe( resp => {
      if (resp.response === 'ok') {
        Swal.fire({
         title: 'correcto',
         text: 'Se Registro correctamente',
         type: 'success'
        });
        this.form.reset();
        this.router.navigate(['/asignacionUniforme', this.id]);
      } else {
         Swal.fire({
           title: 'Error',
           text: 'Intentelo de nuevo',
           type: 'warning'
          });
        }
    });
  }
}
