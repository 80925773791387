import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { ProductoModel } from '../../models/producto.model';
import { ProductoService } from '../../services/producto/producto.service';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.css']
})
export class ProductoComponent implements OnInit {

  producto: ProductoModel = new ProductoModel(0, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '');
  accionUpdate = false;
  token: string;
  constructor(private productoService: ProductoService, private route: ActivatedRoute) { }

  ngOnInit() {

    this.token = localStorage.getItem('token');
    const id = this.route.snapshot.paramMap.get('id');
    if (id !== 'nuevo') {
      this.accionUpdate = true;
      this.productoService.getProductoID(this.token, id)
        .subscribe(resp => {
          this.producto = resp['0'];
        });
    } else {
      this.accionUpdate = false;
    }
  }

  guardar(form: NgForm) {

    if (form.invalid) {
      return;
    }
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    });
    Swal.showLoading();
    let peticion: Observable<any>;
    if (this.producto.claveProducto) {
      peticion = this.productoService.actualizarProducto(this.producto, this.token);
    } else {
      peticion = this.productoService.crearProducto(this.producto, this.token);
    }
    peticion.subscribe(resp => {
      Swal.fire({
        title: this.producto.identificador,
        text: 'Se Registro correctamente',
        type: 'success'
      });
    });
  }
}
