<div class="row  col-md-12 animated fadeIn">
    <div class="col-xl-4 col-md-6 mb-4">
        <div class="card border border-secondary mb-3">
            <div class="card-body">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-dark text-uppercase mb-1"> {{ texto[0] }}</div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{ numeros[0] }}</div>
                    </div>
                    <div class="col-auto">
                        <i class="{{icono[0]}}  fa-2x text-gray-300"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-4 col-md-6 mb-4">
        <div class="card border border-secondary mb-3">
            <div class="card-body">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-dark text-uppercase mb-1"> {{ texto[1] }}</div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{ numeros[1] }}</div>
                    </div>
                    <div class="col-auto">
                        <i class="{{icono[1]}}  fa-2x text-gray-300"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-4 col-md-6 mb-4">
        <div class="card border border-secondary mb-3">
            <div class="card-body">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-dark text-uppercase mb-1"> {{ texto[2] }}</div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{ numeros[2] }}</div>
                    </div>
                    <div class="col-auto">
                        <i class="{{icono[2]}}  fa-2x text-gray-300"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>