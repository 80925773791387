import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { AuthGuard } from '../guards/auth.guard';
import { VerificaTokenGuard } from '../guards/verifica-token.guard';
import { DhasboardComponent } from './dhasboard/dhasboard.component';
import { Graficas1Component } from './graficas1/graficas1.component';
import { AdminGuard } from '../guards/admin.guard';
import { DireccionGuard } from '../guards/direccion.guard';
import { ServicioClienteComponent } from './servicio-cliente/servicio-cliente.component';
import { ClientesComponent } from './clientes/clientes.component';
import { ComercialGuard } from '../guards/comercial.guard';
import { ClienteComponent } from './cliente/cliente.component';
import { ContratacionesComponent } from './contrataciones/contrataciones.component';
import { RhGuard } from '../guards/rh.guard';
import { ContratacionComponent } from './contratacion/contratacion.component';
import { VacantesComponent } from './vacantes/vacantes.component';
import { VacanteComponent } from './vacante/vacante.component';
import { AccoutSettingsComponent } from './accout-settings/accout-settings.component';

import { PlantillasComponent } from './plantillas/plantillas.component';
import { PlantillaComponent } from './plantilla/plantilla.component';
import { NominasGuard } from '../guards/nominas.guard';

import { BajasComponent } from '../pages/bajas/bajas.component';
import { AlmacenGuard } from '../guards/almacen.guard';
import { AdministrativosComponent } from './administrativos/administrativos.component';
import { ReportesemanalComponent } from './reportesemanal/reportesemanal.component';


const pagesRoutes: Routes = [
    {
        path: '',
        component: PagesComponent,
        canActivate: [ AuthGuard, VerificaTokenGuard ],
        children: [
            { path: 'dhasboard',
              component: DhasboardComponent,
              data: { titulo: 'PeopleTalent' },
              canActivate: [ AuthGuard, VerificaTokenGuard ]
            },
            { path: 'graficas1',
               component: Graficas1Component,
               data: { titulo: 'Estadisticas',
               canActivate: [ AuthGuard, VerificaTokenGuard, AdminGuard, DireccionGuard ]
              }
             },
             { path: 'reportesemanal',
               component: ReportesemanalComponent,
               data: { titulo: 'Reporte semanal',
               canActivate: [ AuthGuard, VerificaTokenGuard, AdminGuard, DireccionGuard ]
              }
             },
             { path: 'servicio-cliente',
               component: ServicioClienteComponent,
               data: { titulo: 'ServicioClientes' },
               canActivate: [ AuthGuard, VerificaTokenGuard ]
             },
             { path: 'clientes',
               component: ClientesComponent,
               data: { titulo: 'Clientes' },
               canActivate: [ AuthGuard, VerificaTokenGuard, ComercialGuard]
             },
             { path: 'cliente/:id',
               component: ClienteComponent,
               data: {titulo: 'Clientes' },
               canActivate: [ AuthGuard, VerificaTokenGuard, ComercialGuard ]
             },
             { path: 'contrataciones',
               component: ContratacionesComponent,
               data: {titulo: 'Contrataciones' },
               canActivate: [ AuthGuard, VerificaTokenGuard, NominasGuard ]
             },
             { path: 'contratacion/:id',
             component: ContratacionComponent,
             data: {titulo: 'Contratacion' },
             canActivate: [ AuthGuard, VerificaTokenGuard, RhGuard  ]
           },
           { path: 'administrativos',
               component: AdministrativosComponent,
               data: {titulo: 'Administrativos' },
               canActivate: [ AuthGuard, VerificaTokenGuard, NominasGuard ]
             },
           { path: 'vacantes',
               component: VacantesComponent,
               data: { titulo: 'Vacantes' },
               canActivate: [ AuthGuard, VerificaTokenGuard, RhGuard  ]
           },
           { path: 'vacante/:id',
             component: VacanteComponent,
             data: {titulo: 'Vacante' },
             canActivate: [ AuthGuard, VerificaTokenGuard, RhGuard  ]
           },
           { path: 'accout-settings',
               component: AccoutSettingsComponent,
               data: { titulo: 'Ajustes' },
               canActivate: [ AuthGuard, VerificaTokenGuard ]
            },
            { path: 'plantillas/:id',
             component: PlantillasComponent,
             data: { titulo: 'Plantillas' },
             canActivate: [ AuthGuard, VerificaTokenGuard, ComercialGuard]
            },
            { path: 'plantilla/:id',
             component: PlantillaComponent,
             data: {titulo: 'Plantilla' },
             canActivate: [ AuthGuard, VerificaTokenGuard, ComercialGuard ]
            },
            { path: 'plantilla/nuevo',
             component: PlantillaComponent,
             data: {titulo: 'Plantilla' },
             canActivate: [ AuthGuard, VerificaTokenGuard, ComercialGuard ]
            },
            { path: '',
               redirectTo: '/dhasboard',
               pathMatch: 'full'
            },
            
            { path: 'bajas',
            component: BajasComponent,
            data: { titulo: 'Bajas' },
            canActivate: [ AuthGuard, VerificaTokenGuard, AlmacenGuard]
          }
            ]
    }
];


export const PAGES_ROUTES = RouterModule.forChild( pagesRoutes );
