<!-- The Modal -->
<div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;  padding-left: 0px;">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header bg-theme">
                <h4 class="modal-title text-center text-white" id="myLargeModalLabel">DETALLE EQUIPO ASIGNADO</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body bg-light">
                <!-- ---------------------------------------------------------------------------------------------------------------- -->
                <div class="card bg-light">
                    <!--img [src]='empleadoID.urlphoto' class="align-self-center" width='162px' height='162px'-->
                    <div class="card-body bg-light">
                        <h2 class="text-center"></h2>
                        <ul class="list-group">
                            <div class="row">
                                <li class="list-group-item list-group-item-primary col-12">
                                    <p class="font-weight-bold text-center">DATOS DE EQUIPO</p>
                                </li>
                            </div>
                            <div class="row">
                                <li class="list-group-item bg-light col-6">
                                    <p class="font-weight-bold">IDENTIFICADOR:</p> {{ productoID.identificador }}
                                </li>
                                <li class="list-group-item bg-light col-6">
                                    <p class="font-weight-bold">MODELO:</p> {{ productoID.modelo }}
                                </li>
                            </div>
                            <div class="row">
                                <li class="list-group-item bg-light col-4">
                                    <p class="font-weight-bold">PROCESADOR:</p> {{ productoID.procesador }}
                                </li>
                                <li class="list-group-item bg-light col-4">
                                    <p class="font-weight-bold">RAM:</p> {{ productoID.ram }}
                                </li>
                                <li class="list-group-item bg-light col-4">
                                    <p class="font-weight-bold">DISCO DURO:</p> {{ productoID.discoDuro }}
                                </li>
                            </div>
                            <div class="row">
                                <li class="list-group-item bg-light col-5">
                                    <p class="font-weight-bold text-center">SISTEMA OPERATIVO:</p> {{ productoID.sistemaOperativo }}
                                </li>
                                <li class="list-group-item bg-light col-7">
                                    <p class="font-weight-bold text-center">OBSERVACIÓN:</p> {{ productoID.observacion }}
                                </li>
                            </div>
                            <div class="row">
                                <li class="list-group-item bg-light col-8">
                                    <p class="font-weight-bold">TIPO DE EQUIPO:</p> {{ productoID.tipoProducto }}
                                </li>
                                <li class="list-group-item bg-light col-4">
                                    <p class="font-weight-bold">ESTATUS:</p> {{ productoID.status }}
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
                <!------------------------------------------------------------------------------------------------------------------------>
            </div>
            <div class="modal-footer bg-light">
                <button type="button" class="btn btn-danger waves-effect text-left" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!--**********************************************************************************************************-->
<app-head-estadistico
[texto] = texto
[icono] = icon
[numeros] = numeros
></app-head-estadistico>
<div class="row animated fadeIn">

</div>
<div class='row animated fadeIn'>
    <div class="col-12">
        <div class="card">


            <div class="card-body">
                <div class="card">
                    <div class="row col-sm-12">
                        <div class="col-sm-10">
                            <input #input (keyup)="buscarEquipo( input.value )" name="termino" type="text" class="form-control" placeholder="Buscar Equipo..">
                        </div>
                        <div class="col-sm-2">
                            <div class="text-right">
                                <button [routerLink]="['/asignacion-equipo/nuevo']" type="button" class="btn waves-effect waves-light btn-rounded btn-themecolor">
                                                       <i class="fa fa-plus"> Asignar Equipo</i>
                            </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="table-responsive">
                    <table *ngIf="!cargando" class="table table-striped animated fadeIn faster" width="100%" cellspacing="0">
                        <thead class="bg-theme">
                            <tr>
                                <th>
                                    <p class="font-weight-bold text-white">Imagen</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Nombre</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Departamento</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Equipo Asignado</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Tipo de Equipo</p>
                                </th>
                                <p class="font-weight-bold text-white">Acción</p>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let p of producto | paginate: config; let i = index">
                                <td><img [src]='p.urlphoto' width='100px' height='100px' class="rounded" /></td>
                                <td>{{ p.nombre + ' '+ p.apellido | lowercase }}</td>
                                <td>{{ p.tipoDepartamento | lowercase }} </td>
                                <td>{{ p.identificador | uppercase }}</td>
                                <td>{{ p.tipoProducto | lowercase }} </td>
                                <td>
                                    <button class="btn btn-warning mr-1" [routerLink]="['/producto',p.claveProducto]">                        
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                  <button (click)="detalleInfo(p.claveProducto)" class="btn btn-success m-1" data-toggle="modal" data-target=".bs-example-modal-lg">        
                                    <i class="fa fa-eye"></i>
                                    </button>
                                   <!--button class="btn btn-danger" (click)="borrarCliente( f ,i)">
                                    <i class="fa fa-trash"></i>
                                    </button
                                   -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div align="right">
                        <pagination-controls (pageChange)="pageChanged($event)" autoHide="true" responsivo="true" directionLinks="true" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
                    </div>
                </div>
                <div *ngIf="cargando && result == false" class="alert alert-info text-center mt-3 animated fadeIn faster">
                    <h4 clas="alert-heading">Cargando</h4>
                    <p>
                        <i class="fa fa-sync-alt fa-spin fa 2x"></i>
                    </p>
                    <p class="mb-0">
                        Espere por favor
                    </p>
                </div>
                <div *ngIf="!cargando && result == true" class="alert alert-warning text-center mt-3">
                    <h4 clas="alert-heading">No Se encontraron registros</h4>
                    <p>
                        <i class="fa fa-exclamation fa 2x"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>