<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <input name="filtroNombre" type="text" class="form-control" placeholder="Buscar Empleado..." [(ngModel)]="filtroNombre">
        </div>
    </div>
</div>
<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title font-weight-bold"> Empleados registrados ({{ empleado.length }})</h3>
                <div class="table-responsive">
                    <table *ngIf="!cargando && empleado.length > 0" class="table table-striped animated fadeIn faster" width="100%" cellspacing="0">
                        <thead class="bg-theme">
                            <tr>
                                <th>
                                    <p class="font-weight-bold text-white">Imagen</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Nombre</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Puesto</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">#</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Acciones</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let f of empleado | filterNameEmpleado: filtroNombre | paginate: config; let i = index">
                                <td><img [src]='f.urlphoto' width='100px' height='100px' /></td>
                                <td>{{ f.nombre | titlecase }} {{ f.apellido | titlecase }}</td>
                                <td>{{ f.puesto | titlecase }} </td>
                                <td>{{ f.numeroDeEmpleado | uppercase }} </td>

                                <td>
                                    <button (click)="detalleInfo(f.claveEmpleado)" class="btn btn-primary btn-sm m-1" [routerLink]="['/uniforme',f.claveEmpleado]">
                                        Asignar uniforme
                                    </button>
                                    <button (click)="detalleInfo(f.claveEmpleado)" class="btn btn-warning btn-sm m-1" [routerLink]="['/asignacionUniforme',f.claveEmpleado]">
                                        Consultar Uniforme
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div align="right">
                        <pagination-controls (pageChange)="pageChanged($event)" autoHide="true" responsivo="true" directionLinks="true" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
                    </div>
                </div>
                <div *ngIf="cargando" class="alert alert-info text-center mt-3 animated fadeIn faster">
                    <h4 clas="alert-heading">Cargando</h4>
                    <p>
                        <i class="fa fa-sync-alt fa-spin fa 2x"></i>
                    </p>
                    <p class="mb-0">
                        Espere por favor
                    </p>
                </div>
                <div *ngIf="!cargando && result == true" class="alert alert-warning text-center mt-3">
                    <h4 clas="alert-heading">No Se encontraron registros</h4>
                    <p>
                        <i class="fa fa-exclamation fa 2x"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>