<div class="row">
    <!-- Earnings (Monthly) Card Example -->
    <div class="col-xl-4 col-md-6 mb-3">
        <div class="card">
            <div class="card-body alert-dark">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-dark text-uppercase mb-1">Movimientos/ Día</div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{ movimientosTotales }}</div>
                    </div>
                    <div class="col-auto">
                        <i class="fas fa-refresh fa-2x text-gray-300"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Pending Requests Card Example -->
    <div class="col-xl-4 col-md-6 mb-3">
        <div class="card">
            <div class="card-body alert-success">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-success text-uppercase mb-1">Entradas/ Día</div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{ entradasTotales }}</div>
                    </div>
                    <div class="col-auto">
                        <i class="fa fa-level-up fa-2x text-success"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Pending Requests Card Example -->
    <div class="col-xl-4 col-md-6 mb-3">
        <div class="card">
            <div class="card-body alert-danger">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">Salidas/ Día</div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800">{{ salidasTotales }}</div>
                    </div>
                    <div class="col-auto">
                        <i class="fa fa-level-down fa-2x text-danger"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-7">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">{{ graficos.grafico1.leyenda }} </h4>
                <app-grafico-dona [chartLabels]="graficos.grafico1.labels" [chartData]="graficos.grafico1.data" chartType="doughnut"></app-grafico-dona>
            </div>
        </div>
    </div>
    <div class="col-md-5">
        <div class="row">
            <div class="col-xl-12 col-md-6 mb-4">
                <div class="card">
                    <div class="card-body alert-warning">
                        <div class="row align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-dark mb-1">Descuentos/ Día</div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{ 0 | currency:'MXN':'symbol-narrow' }}</div>
                            </div>
                            <div class="col-auto">
                                <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-md-6 mb-4">
                <div class="card">
                    <div class="card-body alert-dark">
                        <div class="row align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-dark mb-1">Sin Descuento/ Día</div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{ 0 | currency:'MXN':'symbol-narrow' }}</div>
                            </div>
                            <div class="col-auto">
                                <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-md-6 mb-4">
                <div class="card">
                    <div class="card-body alert-success">
                        <div class="row align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-dark mb-1">Cambio por cambio/ Día</div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{ 0 | currency:'MXN':'symbol-narrow' }}</div>
                            </div>
                            <div class="col-auto">
                                <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<h3 align="center">BALANCE DEL MES</h3>
<div class="row">
    <div class="col-xl-6 col-md-6 mb-4">
        <div class="card">
            <div class="card-body">
                <div class="row align-items-center alert alert-success">
                    <div class="col mr-2 ">
                        <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Entrada de uniforme del mes</div>
                        <div class="h5 mb-0 font-weight-bold text-info">{{ 0 }}</div>
                    </div>
                    <div class="col-auto">
                        <i class="fa fa-level-up  fa-2x text-gray-300"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-6 col-md-6 mb-4">
        <div class="card">
            <div class="card-body">
                <div class="row align-items-center alert alert-danger">
                    <div class="col mr-2 ">
                        <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Salida de uniforme del mes</div>
                        <div class="h5 mb-0 font-weight-bold text-info">{{ 0 }}</div>
                    </div>
                    <div class="col-auto">
                        <i class="fa fa-level-down  fa-2x text-gray-300"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<h3 align="center">BALANCE GENERAL</h3>
<!--div class="row">
    <div class="col-xl-12 col-md-6 mb-4">
        <div class="card">
            <div class="card-body">
                <div class="row align-items-center">
                    <div class="col mr-2 alert alert-warning text-center">
                        <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Utilidad efectiva obtenida del mes (hasta el día de hoy antes de impuestos)</div>
                        <div *ngIf="utilidadMes>=0" class="h5 mb-0 font-weight-bold text-success">Actualmente con una utilidad de {{ utilidadMes | currency:'MXN':'symbol-narrow' }}</div>
                        <div *ngIf="utilidadMes<0" class="h5 mb-0 font-weight-bold text-danger">Actualmente con perdida {{ utilidadMes | currency:'MXN':'symbol-narrow' }} </div>
                    </div>
                    <div class="col-auto">
                        <i class="fas fa-dollar-sign  fa-2x text-gray-300"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div-->
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">{{ graficos2.grafico2.leyenda }} </h4>
                <app-grafico-bar [chartLabels]="graficos2.grafico2.labels" [chartData]="graficos2.grafico2.data" chartType="bar"></app-grafico-bar>
            </div>
        </div>
    </div>
</div>