import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { PlantillaService } from '../../../services/plantilla/plantilla.service';
import { UniformeModel } from '../../models/uniforme.model';
@Component({
  selector: 'app-uniforme-asignado',
  templateUrl: './uniforme-asignado.component.html',
  styleUrls: ['./uniforme-asignado.component.css']
})
export class UniformeAsignadoComponent implements OnInit {
  
  filtroNombre: string = '';
  uniforme: UniformeModel[] = [];
  uniformeID: UniformeModel[] = [];
  papeleta: string;
  nombre: string;
  cargando = false;
  result = false;
  token: string;
  config: any;
  id: any;
  costo: string;
  servicios: string;
  constructor(public plantillaService: PlantillaService) { }

   pageChanged( event ) {
    this.config.currentPage = event;
  }

  ngOnInit() {
    this.config = {
      itemsPerPage: 10,
      currentPage: 1
    };

    this.cargando = true;
    this.token = localStorage.getItem('token');
    this.cargarPlantilla(this.id);
  }

  cargarPlantilla(id: string) {
    this.plantillaService.getAsignacionesUniformes(this.token)
    .subscribe( (resp: any) => {
      if (resp.response.length > 0) {
         this.uniforme = resp.response;
         this.nombre = resp.response['0'].nombre + resp.response['0'].apellido;
      } else {
        this.result = true;
      }
      this.cargando = false;
    });
  }

  detalleFolio( vacante: string) {
    if ( vacante !== '' ) {
      this.plantillaService.getPapeletaID(this.token, vacante )
      .subscribe( resp => {
        this.uniformeID = resp.response;
        this.papeleta = resp.response['0'].folioPapeleta
       });
   }
  }
}
