<div class="col-12">
    <form [formGroup]="form" (submit)="submit()" #myform="ngForm">
        <div class="card">

            <div class="card-header">Plantilla Autorizada</div>
            <div class="card-body border border-secondary">
                <div class="text-right">
                    <button [routerLink]="['/cliente',id]" type="button" class="btn waves-effect waves-light btn-rounded btn-danger">
                                               <i class="fa fa-arrow-left"> Regresar</i>
                    </button>

                </div>
                <div class="row">
                    <div class="form-group col-4">
                        <label>Nombre del Servicio</label>
                        <input class="form-control" formControlName="servicio" type="text">
                        <span class="cross-validation-error-message alert alert-danger" *ngIf="form.get('servicio').hasError('required') && myform.submitted">
                                el nombre del servicio es necesario
                        </span>
                    </div>
                    <div class="form-group col-4">
                        <label>Costo del servicio</label>
                        <div class="input-group">
                            <div class="input-group-addon"><span>$</span></div>
                            <input class="form-control" formControlName="costoServicio" type="number" min="0" value="0" step="0.01">
                        </div>
                        <span class="cross-validation-error-message alert alert-danger" *ngIf="form.get('costoServicio').hasError('required') && myform.submitted">
                                El costo del servicio es necesario
                        </span>

                    </div>
                    <div class="form-group col-4">
                        <label>Zona del servicio</label>
                        <div class="input-group">
                            <div class="input-group-addon"><i class="ti-split-v"></i></div>
                            <select class="form-control" formControlName="zonaServicio" type="text">
                                    <option value="Queretaro">Sur (Queretaro)</option>
                                    <option value="PIQ">Norte (PIQ)</option>
                                    <option value="SLP">SLP</option>
                                  </select>
                        </div>
                        <span class="cross-validation-error-message alert alert-danger" *ngIf="form.get('zonaServicio').hasError('required') && myform.submitted">
                             la zona es necesaria
                    </span>
                    </div>
                </div>
            </div>

            <div class="card-body border border-secondary">
                <button class="btn btn-primary m-1 col-3" type="button" (click)="AgregarPersona('coordinador')"><i class="fa fa-plus-circle"></i><strong> Agregar Coordinador </strong><span class="badge badge-light"><strong>{{contactFormGroup.controls.length}}</strong></span></button>
                <button class="btn btn-primary m-1 col-3" type="button" (click)="AgregarPersona('js')"><i class="fa fa-plus-circle"></i><strong> Agregar JS </strong><span class="badge badge-light"><strong>{{contactFormGroupJS.controls.length}}</strong></span></button>
                <button class="btn btn-primary m-1 col-3" type="button" (click)="AgregarPersona('jt')"><i class="fa fa-plus-circle"></i><strong> Agregar JT </strong><span class="badge badge-light"><strong>{{contactFormGroupJT.controls.length}}</strong></span></button>
                <button class="btn btn-primary m-1 col-3" type="button" (click)="AgregarPersona('recepcionista')"><i class="fa fa-plus-circle"></i><strong> Agregar Recepcionista </strong><span class="badge badge-light"><strong>{{contactFormGroupRecepcionista.controls.length}}</strong></span></button>
                <button class="btn btn-primary m-1 col-3" type="button" (click)="AgregarPersona('central')"><i class="fa fa-plus-circle"></i> <strong> Agregar Central </strong><span class="badge badge-light"><strong>{{contactFormGroupCentral.controls.length}}</strong></span></button>
                <button class="btn btn-primary m-1 col-3" type="button" (click)="AgregarPersona('monitorista')"><i class="fa fa-plus-circle"></i><strong> Agregar Monitorista </strong><span class="badge badge-light"><strong>{{contactFormGroupMonitorista.controls.length}}</strong></span></button>
                <button class="btn btn-primary m-1 col-3" type="button" (click)="AgregarPersona('rondinero')"><i class="fa fa-plus-circle"></i><strong> Agregar Rondinero </strong> <span class="badge badge-light"><strong>{{contactFormGroupRondinero.controls.length}}</strong></span></button>
                <button class="btn btn-primary m-1 col-3" type="button" (click)="AgregarPersona('guardiaSeguridad')"><i class="fa fa-plus-circle"></i><strong> Agregar GS </strong><span class="badge badge-light"><strong>{{contactFormGroupGuardiaSeguridad.controls.length}}</strong></span></button>
            </div>
            <div *ngIf="contactFormGroup.length" class="card-body border border-secondary" formArrayName="coordinadores">
                <div class="card-header">
                    <h4><strong>Coordinadores</strong></h4>
                </div>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let contact of contactFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Turno Coordinador # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="turnoCoordinador" type="text">
                                            <option *ngFor="let turn of turno; let i = index" [value]="turn.claveTurno">{{ turn.turno }}</option>
                                         </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroup(i).controls['turnoCoordinador'].hasError('required') && myform.submitted">
                                        el turno es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">Sueldo Coordinador # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>$</span></div>
                                        <input class="form-control" formControlName="sueldoCoordinador" type="number" min="0" value="0" step="0.01">
                                        <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroup(i).controls['sueldoCoordinador'].touched && getContactsFormGroup(i).controls['sueldoCoordinador'].hasError('required')">
                                            el sueldo es requerido 
                                        </span>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroup(i).controls['sueldoCoordinador'].hasError('required') && myform.submitted">
                                        el sueldo es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Costo Coordinador # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>$</span></div>
                                        <input class="form-control" formControlName="costoCoordinador" type="number" min="0" value="0" step="0.01">
                                        <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroup(i).controls['costoCoordinador'].touched && getContactsFormGroup(i).controls['costoCoordinador'].hasError('required')">
                                          el costo es requerido 
                                        </span>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroup(i).controls['costoCoordinador'].hasError('required') && myform.submitted">
                                        el costo es requerido
                                   </span>
                                </div>
                                <label class="col-sm-3 control-label">Razon social Coordinador # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="razonSocialCoordinador" type="text">
                                                <option value="SPE MINIMOS">SPE MINIMOS</option><option value="SPE MAXIMOS">SPE MAXIMOS</option>
                                                <option value="GOALTUS">GOALTUS</option>
                                              </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroup(i).controls['razonSocialCoordinador'].hasError('required') && myform.submitted">
                                        la razón social es requerida
                                </span>
                                </div>
                                <div class="col-sm-12" align="right">
                                    <button class="btn btn-danger m-t-10" type="button" (click)="quitarPersona(i,'coordinador')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div *ngIf="contactFormGroupJS.length" class="card-body border border-secondary" formArrayName="JS">
                <div class="card-header">
                    <h4><strong>JS</strong></h4>

                </div>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let contact of contactFormGroupJS.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Turno de JS # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group m-b-5">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="turnoJS" type="text">
                                         <option *ngFor="let turn of turno; let i = index" [value]="turn.claveTurno">{{ turn.turno }}</option>
                                        </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupJS(i).controls['turnoJS'].hasError('required') && myform.submitted">
                                        el turno es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">Sueldo de JS # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>$</span></div>
                                        <input class="form-control" formControlName="sueldoJS" type="number" min="0" value="0" step="0.01">
                                        <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupJS(i).controls['sueldoJS'].touched && getContactsFormGroupJS(i).controls['sueldoJS'].hasError('required')">
                      Label is required! </span>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupJS(i).controls['sueldoJS'].hasError('required') && myform.submitted">
                                        el sueldo es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Costo JS # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>$</span></div>
                                        <input class="form-control" formControlName="costoJS" type="number" min="0" value="0" step="0.01">
                                        <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupJS(i).controls['costoJS'].touched && getContactsFormGroupJS(i).controls['costoJS'].hasError('required')">
                      el costo es requerido </span>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupJS(i).controls['costoJS'].hasError('required') && myform.submitted">
                                        el costo es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Razon social JS # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="razonSocialJS" type="text">
                                                <option value="SPE MINIMOS">SPE MINIMOS</option><option value="SPE MAXIMOS">SPE MAXIMOS</option>
                                                <option value="GOALTUS">GOALTUS</option>
                                              </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupJS(i).controls['razonSocialJS'].hasError('required') && myform.submitted">
                                        la razón social es requerida
                                </span>
                                </div>
                                <div class="col-sm-12" align="right">
                                    <button class="btn btn-danger mt-10" type="button" (click)="quitarPersona(i,'js')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="contactFormGroupJT.length" class="card-body border border-secondary" formArrayName="JT">
                <div class="card-header">
                    <h4><strong>JT</strong></h4>

                </div>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let contact of contactFormGroupJT.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Turno de JT # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="turnoJT" type="text">
                                            <option *ngFor="let turn of turno; let i = index" [value]="turn.claveTurno">{{ turn.turno }}</option>
                                        </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupJT(i).controls['turnoJT'].hasError('required') && myform.submitted">
                                        el turno es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">Sueldo de JT # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>$</span></div>
                                        <input class="form-control" formControlName="sueldoJT" type="number" min="0" value="0" step="0.01">

                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupJT(i).controls['sueldoJT'].hasError('required') && myform.submitted">
                                        el sueldo es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label">Costo JT # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>$</span></div>
                                        <input class="form-control" formControlName="costoJT" type="number" min="0" value="0" step="0.01">
                                        <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupJT(i).controls['costoJT'].touched && getContactsFormGroupJT(i).controls['costoJT'].hasError('required')">
                                             el costo es requerido </span>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupJT(i).controls['costoJT'].hasError('required') && myform.submitted">
                                        el costo es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Razon social JT # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="razonSocialJT" type="text">
                                                <option value="SPE MINIMOS">SPE MINIMOS</option><option value="SPE MAXIMOS">SPE MAXIMOS</option>
                                                <option value="GOALTUS">GOALTUS</option>
                                              </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupJT(i).controls['razonSocialJT'].hasError('required') && myform.submitted">
                                        la razón social es requerida
                                </span>
                                </div>
                                <div class="col-sm-12" align="right">
                                    <button class="btn btn-danger mt-10" type="button" (click)="quitarPersona(i,'jt')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="contactFormGroupRecepcionista.length" class="card-body border border-secondary" formArrayName="recepcionista">
                <div class="card-header">
                    <h4><strong>Recepcionista</strong></h4>

                </div>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let contact of contactFormGroupRecepcionista.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Turno de Recepcionista # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="turnoRecepcionista" type="text">
                                                <option *ngFor="let turn of turno; let i = index" [value]="turn.claveTurno">{{ turn.turno }}</option>
                                        </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupRecepcionista(i).controls['turnoRecepcionista'].hasError('required') && myform.submitted">
                                        el turno es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">Sueldo de Recepcionista # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>$</span></div>
                                        <input class="form-control" formControlName="sueldoRecepcionista" type="number" min="0" value="0" step="0.01">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupRecepcionista(i).controls['sueldoRecepcionista'].hasError('required') && myform.submitted">
                                        el sueldo es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label">Costo Recepcionista # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>$</span></div>
                                        <input class="form-control" formControlName="costoRecepcionista" type="number" min="0" value="0" step="0.01">
                                        <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupRecepcionista(i).controls['costoRecepcionista'].touched && getContactsFormGroupRecepcionista(i).controls['costoRecepcionista'].hasError('required')">
                      el costo es requerido </span>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupRecepcionista(i).controls['costoRecepcionista'].hasError('required') && myform.submitted">
                                        el costo es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Razon social Recepcionista # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="razonSocialRecepcionista" type="text">
                                                <option value="SPE MINIMOS">SPE MINIMOS</option><option value="SPE MAXIMOS">SPE MAXIMOS</option>
                                                <option value="GOALTUS">GOALTUS</option>
                                              </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupRecepcionista(i).controls['razonSocialRecepcionista'].hasError('required') && myform.submitted">
                                        la razón social es requerida
                                    </span>
                                </div>
                                <div class="col-sm-12" align="right">
                                    <button class="btn btn-danger mt-10" type="button" (click)="quitarPersona(i,'recepcionista')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="contactFormGroupCentral.length" class="card-body border border-secondary" formArrayName="central">
                <div class="card-header">
                    <h4><strong>Central</strong></h4>
                </div>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let contact of contactFormGroupCentral.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Turno de Central # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="turnoCentral" type="text">
                                                <option *ngFor="let turn of turno; let i = index" [value]="turn.claveTurno">{{ turn.turno }}</option>
                                        </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupCentral(i).controls['turnoCentral'].hasError('required') && myform.submitted">
                                        el turno es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">Sueldo de Central # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>$</span></div>
                                        <input class="form-control" formControlName="sueldoCentral" type="number" min="0" value="0" step="0.01">
                                        <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupCentral(i).controls['sueldoCentral'].touched && getContactsFormGroupCentral(i).controls['sueldoCentral'].hasError('required')">
                      Label is required! </span>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupCentral(i).controls['sueldoCentral'].hasError('required') && myform.submitted">
                                        el sueldo es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label">Costo Central # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>$</span></div>
                                        <input class="form-control" formControlName="costoCentral" type="number" min="0" value="0" step="0.01">
                                        <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupCentral(i).controls['costoCentral'].touched && getContactsFormGroupCentral(i).controls['costoCentral'].hasError('required')">
                      el costo es requerido </span>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupCentral(i).controls['costoCentral'].hasError('required') && myform.submitted">
                                        el costo es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label">Razon social Central # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="razonSocialCentral" type="text">
                                                <option value="SPE MINIMOS">SPE MINIMOS</option><option value="SPE MAXIMOS">SPE MAXIMOS</option>
                                                <option value="GOALTUS">GOALTUS</option>
                                              </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupCentral(i).controls['razonSocialCentral'].hasError('required') && myform.submitted">
                                        la razón social es requerida
                                    </span>
                                </div>
                                <div class="col-sm-12" align="right">
                                    <button class="btn btn-danger mt-10" type="button" (click)="quitarPersona(i,'central')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="contactFormGroupMonitorista.length" class="card-body border border-secondary" formArrayName="monitorista">
                <div class="card-header">
                    <h4><strong>Monitorista</strong></h4>
                </div>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let contact of contactFormGroupMonitorista.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Turno de Monitorista # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="turnoMonitorista" type="text">
                                                <option *ngFor="let turn of turno; let i = index" [value]="turn.claveTurno">{{ turn.turno }}</option>
                                        </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupMonitorista(i).controls['turnoMonitorista'].hasError('required') && myform.submitted">
                                      el turno es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">Sueldo de Monitorista # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>$</span></div>
                                        <input class="form-control" formControlName="sueldoMonitorista" type="number" min="0" value="0" step="0.01">
                                        <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupMonitorista(i).controls['sueldoMonitorista'].touched && getContactsFormGroupMonitorista(i).controls['sueldoMonitorista'].hasError('required')">
                      Label is required! </span>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupMonitorista(i).controls['sueldoMonitorista'].hasError('required') && myform.submitted">
                                      el sueldo es requerido
                                   </span>

                                </div>
                                <label class="col-sm-3 control-label">Costo Monitorista # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>$</span></div>
                                        <input class="form-control" formControlName="costoMonitorista" type="number" min="0" value="0" step="0.01">
                                        <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupMonitorista(i).controls['costoMonitorista'].touched && getContactsFormGroupMonitorista(i).controls['costoMonitorista'].hasError('required')">
                      el costo es requerido </span>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupMonitorista(i).controls['costoMonitorista'].hasError('required') && myform.submitted">
                                       el costo es requerido
                                   </span>
                                </div>
                                <label class="col-sm-3 control-label">Razon social Monitorista # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="razonSocialMonitorista" type="text">
                                                <option value="SPE MINIMOS">SPE MINIMOS</option><option value="SPE MAXIMOS">SPE MAXIMOS</option>
                                                <option value="GOALTUS">GOALTUS</option>
                                              </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupMonitorista(i).controls['razonSocialMonitorista'].hasError('required') && myform.submitted">
                                       la razón social es requerido
                                   </span>
                                </div>
                                <div class="col-sm-12" align="right">
                                    <button class="btn btn-danger mt-10" type="button" (click)="quitarPersona(i,'monitorista')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="contactFormGroupRondinero.length" class="card-body border border-secondary" formArrayName="rondinero">
                <div class="card-header">
                    <h4><strong>Rondinero</strong></h4>
                </div>
                <hr>
                <div class="row">
                    <div class="col-12" *ngFor="let contact of contactFormGroupRondinero.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Turno de Rondinero # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="turnoRondinero" type="text">
                                                <option *ngFor="let turn of turno; let i = index" [value]="turn.claveTurno">{{ turn.turno }}</option>
                                        </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupRondinero(i).controls['turnoRondinero'].hasError('required') && myform.submitted">
                                       el turno es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">Sueldo de Rondinero # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>$</span></div>
                                        <input class="form-control" formControlName="sueldoRondinero" type="number" min="0" value="0" step="0.01">
                                        <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupRondinero(i).controls['sueldoRondinero'].touched && getContactsFormGroupRondinero(i).controls['sueldoRondinero'].hasError('required')">
                      Label is required! </span>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupRondinero(i).controls['sueldoRondinero'].hasError('required') && myform.submitted">
                                       el sueldo es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label">Costo Rondinero # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>$</span></div>
                                        <input class="form-control" formControlName="costoRondinero" type="number" min="0" value="0" step="0.01">
                                        <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupRondinero(i).controls['costoRondinero'].touched && getContactsFormGroupRondinero(i).controls['costoRondinero'].hasError('required')">
                      el costo es requerido </span>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupRondinero(i).controls['costoRondinero'].hasError('required') && myform.submitted">
                                      el costo es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label">Razon social Rondinero # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="razonSocialRondinero" type="text">
                                                <option value="SPE MINIMOS">SPE MINIMOS</option><option value="SPE MAXIMOS">SPE MAXIMOS</option>
                                                <option value="GOALTUS">GOALTUS</option>
                                              </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupRondinero(i).controls['razonSocialRondinero'].hasError('required') && myform.submitted">
                                       la razón social es requerida
                                    </span>
                                </div>
                                <div class="col-sm-12" align="right">
                                    <button class="btn btn-danger mt-10" type="button" (click)="quitarPersona(i,'rondinero')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="contactFormGroupGuardiaSeguridad.length" class="card-body border border-secondary" formArrayName="guardiaSeguridad">
                <div class="card-header">
                    <h4><strong>Guardia de seguridad</strong></h4>
                </div>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let contact of contactFormGroupGuardiaSeguridad.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Turno de Guardia de seguridad # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="turnoGuardiaSeguridad" type="text">
                                                <option *ngFor="let turn of turno; let i = index" [value]="turn.claveTurno">{{ turn.turno }}</option>
                                        </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupGuardiaSeguridad(i).controls['turnoGuardiaSeguridad'].hasError('required') && myform.submitted">
                                       el turno  es requerido
                                     </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">Sueldo de Guardia de seguridad # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>$</span></div>
                                        <input class="form-control" formControlName="sueldoGuardiaSeguridad" type="number" min="0" value="0" step="0.01">
                                        <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupGuardiaSeguridad(i).controls['sueldoGuardiaSeguridad'].touched && getContactsFormGroupGuardiaSeguridad(i).controls['sueldoGuardiaSeguridad'].hasError('required')">
                      Label is required! </span>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupGuardiaSeguridad(i).controls['sueldoGuardiaSeguridad'].hasError('required') && myform.submitted">
                                      el sueldo es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label">Costo Guardia de seguridad # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>$</span></div>
                                        <input class="form-control" formControlName="costoGuardiaSeguridad" type="number" min="0" value="0" step="0.01">
                                        <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupGuardiaSeguridad(i).controls['costoGuardiaSeguridad'].touched && getContactsFormGroupGuardiaSeguridad(i).controls['costoGuardiaSeguridad'].hasError('required')">
                      el costo es requerido </span>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupGuardiaSeguridad(i).controls['costoGuardiaSeguridad'].hasError('required') && myform.submitted">
                                       el costo es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label">Razon social Guardia de seguridad # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="razonSocialGuardiaSeguridad" type="text">
                                                <option value="SPE MINIMOS">SPE MINIMOS</option><option value="SPE MAXIMOS">SPE MAXIMOS</option>
                                                <option value="GOALTUS">GOALTUS</option>
                                              </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getContactsFormGroupGuardiaSeguridad(i).controls['razonSocialGuardiaSeguridad'].hasError('required') && myform.submitted">
                                      la razón social es requerida
                                    </span>
                                </div>
                                <div class="col-sm-12" align="right">
                                    <button class="btn btn-danger mt-10" type="button" (click)="quitarPersona(i,'guardiaSeguridad')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="card-body" *ngIf="contactFormGroup.length || contactFormGroupJS.length || contactFormGroupJT.length  || contactFormGroupRecepcionista.length || contactFormGroupCentral.length || contactFormGroupMonitorista.length || contactFormGroupRondinero.length || contactFormGroupGuardiaSeguridad.length">
                <div class="col-12">
                    <button class="btn btn-success mr-1" type="submit"> <i class="fa fa-check"></i>  Guardar Plantilla </button>
                    <button [routerLink]="['/clientes']" type="button" class="btn btn-danger">Cancelar</button>

                </div>
            </div>
        </div>
    </form>
</div>