<div class="animated fadeIn faster">
    <hr>
    <div class="row text-right">
        <div class="col">
            <button [routerLink]="['/productos']" class="btn btn-danger">
                <i class="fa fa-arrow-circle-left"></i>
                Regresar
            </button>
        </div>
    </div>
    <div class="row animated fadeIn faster">
        <div class="col">
            <form (ngSubmit)="guardar( f )" #f="ngForm">
                <div class="form-group" *ngIf="accionUpdate">
                    <label> Id Producto</label>
                    <input type="text" class="form-control" name="claveCliente" [(ngModel)]="producto.claveProducto"
                        placeholder="Id Producto" disabled="disabled">
                    <small class="form-text text-muted">Este campo es autogenerado</small>
                </div>
                <div class="form-group">
                    <label> Identificador</label>
                    <input type="text" class="form-control" name="identificador" [(ngModel)]="producto.identificador"
                        placeholder="Identificador">
                </div>
                <div class="form-group">
                    <label> Modelo</label>
                    <input type="text" class="form-control" name="modelo" [(ngModel)]="producto.modelo" required
                        placeholder="Modelo">
                </div>
                <div class="form-group">
                    <label> Procesador</label>
                    <input type="email" class="form-control" name="procesador" [(ngModel)]="producto.procesador"
                        required placeholder="Procesador">
                </div>
                <div class="form-group">
                    <label> Memoria RAM</label>
                    <input type="text" class="form-control" name="ram" [(ngModel)]="producto.ram" required
                        placeholder="Memoria RAM">
                </div>
                <div class="form-group">
                    <label> Disco Duro</label>
                    <input type="text" class="form-control" name="discoDuro" [(ngModel)]="producto.discoDuro" required
                        placeholder="Disco Duro">
                </div>
                <div class="form-group">
                    <label> Sistema Operativo</label>
                    <input type="text" class="form-control" name="sistemaOperativo"
                        [(ngModel)]="producto.sistemaOperativo" required placeholder="Sistema Operativo">
                </div>
                <div class="form-group">
                    <label> Marca</label>
                    <input type="text" class="form-control" name="marca" [(ngModel)]="producto.marca" required
                        placeholder="Marca">
                </div>
                <div class="form-group">
                    <label>Status Producto</label>
                    <select class="form-control" name="status" [(ngModel)]="producto.status" required>
                        <option value="Disponible">Disponible</option>
                        <option value="Asignado">Asignado</option>
                    </select>
                </div>

                <div class="form-group">
                    <label>Tipo Producto</label>
                    <select class="form-control" name="claveTipoProducto" [(ngModel)]="producto.claveTipoProducto"
                        required>
                        <option value="1">Escritorio</option>
                        <option value="2">Laptop</option>
                    </select>
                </div>
                <div class="form-group">
                    <label> serial / Observación</label>
                    <input type="text" class="form-control" name="observacion" [(ngModel)]="producto.observacion"
                        required placeholder="Observacion">
                </div>
                <hr>
                <div class="form-group text-center">
                    <button type="submit" class="btn btn-primary w-25"><i class="fa fa-save"></i> Guardar</button>
                </div>
            </form>
        </div>
    </div>
</div>