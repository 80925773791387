import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterName'
})
export class FilterNamePipe implements PipeTransform {

  transform(value: any, arg: any): any {
    if (arg === '') { return value; }
    const resultClient = [];
    for (const cliente of value) {
      if (cliente.nombreEmpresa.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
        resultClient.push(cliente);
      }
    }
    return resultClient;

  }
}
