import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { URL_SERVICIOS } from '../../config/config';

@Injectable({
  providedIn: 'root'
})
export class ContratacionService {

  totalClientes = 0;
  constructor( private http: HttpClient ) { }

  getEmpleado(token: string, cveEmp: string, tipoRazonSocial: string) {
    return this.http.post(URL_SERVICIOS + '/empleado?token=' + token,
    {
      claveEmpresa: cveEmp,
      razonSocial: tipoRazonSocial
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  getEmpleadoAdministrativo(token: string, cveEmp: string, tipoRazonSocial: string) {
    return this.http.post(URL_SERVICIOS + '/empleado/administrativo?token=' + token,
    {
      claveEmpresa: cveEmp,
      razonSocial: tipoRazonSocial
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  getEmpleadoBaja(token: string, cveEmp: string, tipoRazonSocial: string) {
    return this.http.post(URL_SERVICIOS + '/empleado/empleadoBaja?token=' + token,
    {
      claveEmpresa: cveEmp,
      razonSocial: tipoRazonSocial
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  getEmpleadoBuscar(token: string, tipo: string, termino: string, cveEmp: string ) {
    return this.http.post(`${ URL_SERVICIOS }/empleado/buscar?token=` + token,
    {
        termino,
        cveEmp,
        tipo
    })
    .pipe(
      map( (resp: any) => {
        return resp;
      })
    );
  }

  getEmpleadoID(token: string, id: string) {
    return this.http.post(`${ URL_SERVICIOS }/empleado/detalle?token=` + token,
    {
        id
    })
    .pipe(
      map( (resp: any) => {
        return resp;
      })
    );
  }

  getDirectorio(token: string) {
    return this.http.post(URL_SERVICIOS + '/empleado/directorio?token=' + token,
    {
      token
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }
}
