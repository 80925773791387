import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../../services/sidebar.service';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})
export class SidebarComponent implements OnInit {

  usuario: string;
  departamento: string;
  imagen: string;
  menuSis: string;
  // tslint:disable-next-line: variable-name
  constructor( public _sidebar: SidebarService, private usuarioService: UsuarioService, public router: Router) {}

  ngOnInit() {
    this.usuarioData();
    this.obtenerMenu();
  }

  public usuarioData() {
    this.usuario = localStorage.getItem('usuario');
    this.departamento = localStorage.getItem('departamento');
    this.imagen = localStorage.getItem('urlphoto');
}

  salir() {
     this.usuarioService.logout();
     this.router.navigateByUrl('/login');

  }

  obtenerMenu() {

    if ( localStorage.getItem('departamento') === 'Sistemas') {
      this.menuSis = this._sidebar.menuSistemas;
     } else {
           if ( localStorage.getItem('departamento') === 'Direccion') {
             this.menuSis = this._sidebar.menuDireccion;
           } else {
              if ( localStorage.getItem('departamento') === 'Recursos Humanos') {
                this.menuSis = this._sidebar.menuRH;
              }  else {
                if ( localStorage.getItem('departamento') === 'Contrataciones') {
                  this.menuSis = this._sidebar.menuContratacion;
                 } else {
                     if ( localStorage.getItem('departamento') === 'Operaciones') {
                       this.menuSis = this._sidebar.menuOperaciones;
                         } else {
                              if ( localStorage.getItem('departamento') === 'Almacen') {
                                 this.menuSis = this._sidebar.menuAlmacen;
                                } else {
                                   if ( localStorage.getItem('departamento') === 'Comercial') {
                                    this.menuSis = this._sidebar.menuComercial;
                                      } else {
                                        if ( localStorage.getItem('departamento') === 'Nominas') {
                                          this.menuSis = this._sidebar.menuNominas;
                                            } else {
                                              this.menuSis = this._sidebar.menu;
                                             }
                                       }
                                    }
                                }
                        }
               }
           }
       }
     }
}
