<div class="row  animated fadeIn faster">
    <div class="card border border-secondary">
        <div class="row col-lg-12">
            <div class="col-md-3">
                <img src="https://sistemnom.edrum.com.mx/images/icono.png" class="mt-4" width='120px' height='120px'>
            </div>
            <div class="col-md-6 text-center">
                <h2 class="mt-4"><strong>SEGURIDAD PRIVADA EFICIENTE S.A DE C.V</strong></h2><br>
                <h4><strong>CLIENTE SPE</strong></h4>
            </div>
            <div class="col-md-3">
                <<img *ngIf="!imagenTemp" [src]="cliente.urlphoto" class="img-thumbnail mt-4" align="right"
                    width='200px' height='250px'>
                    <img *ngIf="imagenTemp" [src]="imagenTemp" class="img-thumbnail mt-4" align="right" width='200px'
                        height='250px'>
            </div>
        </div>
        <div class="col-lg-12 bg-danger text-center m-b-15">
            <h3 class="text-light">Información de cliente</h3>
        </div>
        <form (ngSubmit)="guardar( f )" #f="ngForm" class="card-body">
            <div class="form-body">
                <div class="row mt-2 col-md-12">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label"><strong>ID SPE</strong></label>
                            <input type="text" class="form-control" name="identificador"
                                [(ngModel)]="cliente.identificador" placeholder="Id cliente">
                            <small class="form-control-feedback"><strong>ID para control interno de
                                    expediente</strong></small>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label"><strong>Fecha de ingreso</strong></label>
                            <input type="date" class="form-control" name="fechaIngreso"
                                [(ngModel)]="cliente.fechaIngreso" required />
                            <small class="form-control-feedback"><strong>Fecha de inico de relación
                                    comercial</strong></small>
                        </div>
                    </div>
                </div>

                <div class="row col-md-12">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label"><strong>Nombre Comercial</strong></label>
                            <input type="text" class="form-control" name="nombreComercial"
                                [(ngModel)]="cliente.nombreComercial" placeholder="Nombre comercial">

                            <small class="form-control-feedback"><strong>Nombre comercial para
                                    identificación</strong></small>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label"><strong>Razón Social</strong></label>
                            <input type="text" class="form-control" name="nombreEmpresa"
                                [(ngModel)]="cliente.nombreEmpresa" placeholder="Razon social">

                            <small class="form-control-feedback"><strong>Razón Social de alta en
                                    hacienda</strong></small> </div>
                    </div>
                </div>

                <div class="row col-md-12">
                    <div class="col-md-6">
                        <h3 class="card-title"><strong>Logo del cliente</strong></h3>

                        <img *ngIf="!imagenTemp" [src]="cliente.urlphoto" class="img-thumbnail" width='200px'
                            height='250px'>
                        <img *ngIf="imagenTemp" [src]="imagenTemp" class="img-thumbnail" width='200px' height='250px'>

                        <input *ngIf="accionUpdate==true" (change)="seleccionImage($event.target.files[0])" type="file"
                            id="urlphoto">
                        <br>
                        <button *ngIf="accionUpdate==true" (click)="cambiarImagen(cliente.claveCliente)" type="button"
                            class="btn btn-block btn-success waves-effect waves-light m-b-10 m-t-10">
                            <i class="fa fa-save"></i>
                            Actualizar Foto
                        </button>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label"><strong>RFC</strong></label>
                            <input type="text" class="form-control" name="rfc" [(ngModel)]="cliente.rfc" required
                                placeholder="RFC">

                            <small class="form-control-feedback"> <strong>Registro federal de contribuyente </strong></small> </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="control-label"><strong>Status cliente</strong></label>
                            <select class="form-control" name="statusCliente" [(ngModel)]="cliente.statuscliente"
                                required>
                                <option selected="selected" value="activo">activo</option>
                                <option value="baja">baja</option>
                                <option value="verificado">verificado</option>
                            </select>
                            <small class="form-control-feedback"><strong>Status cliente</strong></small> </div>
                    </div>
                </div>
                <div class="col-lg-12 bg-danger text-center m-b-15">
                    <h3 class="text-light">Contacto de negociación</h3>
                </div>
                <div class="row col-md-12">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="control-label"><strong>Correo electronico</strong></label>
                            <input type="email" class="form-control" name="correoEletronico"
                                [(ngModel)]="cliente.correoEletronico" required placeholder="Nombre de cliente">

                            <small class="form-control-feedback"><strong>Contacto comercial</strong></small> </div>
                    </div>
                </div>
            </div>
            <div class="form-actions" align="right">
                <button type="submit" class="btn btn-success mr-1"> <i class="fa fa-check"></i> Guardar</button>
                <button [routerLink]="['/clientes']" type="button" class="btn btn-inverse">Cancelar</button>

            </div>
        </form>

        <div class="col-lg-12 bg-danger text-center m-b-15">
            <h3 class="text-light">Integración de plantilla</h3>
        </div>

        <app-plantillas></app-plantillas>

        <div class="col-lg-12 bg-danger text-center m-b-15">
            <h3 class="text-light">Check List de entrega</h3>
        </div>

        <div class="col-md-12">
            <div class="table-responsive">
                <table class="table table-striped animated fadeIn faster" width="100%" cellspacing="0">
                    <thead class="bg-dark">
                        <tr>
                            <th>
                                <p class="text-white">#</p>
                            </th>
                            <th>
                                <p class="text-white">Documento</p>
                            </th>
                            <th>
                                <p class="text-white">Status</p>
                            </th>
                            <th>
                                <p class="text-white">Acción</p>
                            </th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>RFC DEL CLIENTE</td>
                            <td></td>
                            <td>
                                <button class="btn btn-primary btn-sm m-r-10"> 
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                <button class="btn btn-success btn-sm"> 
                                <i class="fa fa-eye"></i>
                              </button>
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>INE Y PODER DEL REP</td>
                            <td></td>
                            <td>
                                <button class="btn btn-primary btn-sm m-r-10"> 
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                <button class="btn btn-success btn-sm"> 
                                <i class="fa fa-eye"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>CONTRATO COMERCIAL FIRMADO</td>
                            <td></td>
                            <td>
                                <button class="btn btn-primary btn-sm m-r-10"> 
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                <button class="btn btn-success btn-sm"> 
                                <i class="fa fa-eye"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>COTIZACIÓN AUTORIZADA</td>
                            <td></td>
                            <td>
                                <button class="btn btn-primary btn-sm m-r-10"> 
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                <button class="btn btn-success btn-sm"> 
                                <i class="fa fa-eye"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>CARTA DE SOLICITUD DE INCREMENTO</td>
                            <td></td>
                            <td>
                                <button class="btn btn-primary btn-sm m-r-10"> 
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                <button class="btn btn-success btn-sm"> 
                                <i class="fa fa-eye"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>AVISO DE INCREMENTO AUTORIZADO FIRMADO
                                POR SPE (DOCTO INTERNO)
                            </td>
                            <td></td>
                            <td>
                                <button class="btn btn-primary btn-sm m-r-10"> 
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                <button class="btn btn-success btn-sm"> 
                                <i class="fa fa-eye"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>FORMATO DE SPE DE INFORMACIÓN FISCAL</td>
                            <td></td>
                            <td>
                                <button class="btn btn-primary btn-sm m-r-10"> 
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                <button class="btn btn-success btn-sm"> 
                                <i class="fa fa-eye"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td>ORDEN DE COMPRA MAYOR A 1 MES</td>
                            <td></td>
                            <td>
                                <button class="btn btn-primary btn-sm m-r-10"> 
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                <button class="btn btn-success btn-sm"> 
                                <i class="fa fa-eye"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>9</td>
                            <td>ESTUDIO DE VULNERABILIDAD</td>
                            <td></td>
                            <td>
                                <button class="btn btn-primary btn-sm m-r-10"> 
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                <button class="btn btn-success btn-sm"> 
                                <i class="fa fa-eye"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>10</td>
                            <td>PROGRAMA DE CAPACITACIÓN ANUAL</td>
                            <td></td>
                            <td><button class="btn btn-primary btn-sm m-r-10"> 
                                <i class="fa fa-pencil"></i>
                              </button>
                            <button class="btn btn-success btn-sm"> 
                            <i class="fa fa-eye"></i>
                            </button>
                        </td>
                        </tr>
                        <tr>
                            <td>11</td>
                            <td>LEVANTAMIENTO DE EQUIPO PARA EL 
                                SERVICIO (CLIENTE)
                            <td></td>
                            <td>
                                <button class="btn btn-primary btn-sm m-r-10"> 
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                <button class="btn btn-success btn-sm"> 
                                <i class="fa fa-eye"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>12</td>
                            <td>INVENTARIO DE EQUIPO PARA EL SERVICIO (SPE)</td>
                            <td></td>
                            <td>
                                <button class="btn btn-primary btn-sm m-r-10"> 
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                <button class="btn btn-success btn-sm"> 
                                <i class="fa fa-eye"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>12</td>
                            <td>GUIA DE CONTRATISTAS Y PROVEEDORES</td>
                            <td></td>
                            <td>
                                <button class="btn btn-primary btn-sm m-r-10"> 
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                <button class="btn btn-success btn-sm"> 
                                <i class="fa fa-eye"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
        </div>
    </div>
    </div>
</div>